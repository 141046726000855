import React, { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'

import Etalons from '../app/pages/Etalon/IndexEtalons.tsx'
import AddEtalon from '../app/pages/Etalon/AddEtalon.tsx'
import EditEtalon from '../app/pages/Etalon/EditEtalon.tsx'

import NotForMobile from '../app/pages/NotForMobile'
import useWindowDimensions from '../app/hooks/useWindowDimensions'

const RouteEtalon: React.FC = (): ReactElement => {
  const { width } = useWindowDimensions()

  return (
    <Routes>
      <Route index element={width > 1050 ? <Etalons /> : <NotForMobile />} />
      <Route path='create' element={width > 1050 ? <AddEtalon /> : <NotForMobile />} />
      <Route path=':etalon_id/edit' element={width > 1050 ? <EditEtalon /> : <NotForMobile />} />
    </Routes>
  )
}

export default RouteEtalon
