// ----------------------------------------------------------------
/* eslint-disable jsx-a11y/anchor-has-content */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable react/jsx-no-target-blank */
// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../../../services/app//axios-config.service'

import Table from '../../LocalElements/Table/Table'
import TableMobile from '../../LocalElements/Table/TableMobile.tsx'
import PencilIcon from '../../../../img/icon/PencilIcon.jsx'
import DeleteIcon from '../../../../img/icon/DeleteIcon'
import Button from '../../../../ui/button/index.jsx'

import Tooltip from '@mui/material/Tooltip'
import { Skeleton } from '@mui/material'
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
} from '../../../../store/slices/app/comps/modal.js'
import {
  setSelectedMeter,
  setSelectedMeters,
  changeMeterWaterInitial,
} from '../../../../store/slices/app/views/meters.js'
import { settingsFileViewer } from '../../../../store/slices/app/comps/modal.js'

import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar.js'
import { setShow } from '../../../../store/slices/app/comps/feedback.js'
import { setName } from '../../../../store/slices/app/controlers/pageName.js'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer.js'
import { refreshMeters } from '../../../../store/slices/app/controlers/updater.js'

import R from '../../../../services/app//request.service.js'
import useWindowDimensions from '../../../hooks/useWindowDimensions.js'

const Tab = ({ onDelete }) => {
  const token = useSelector(state => state.authReducer.authData.token)
  const { width } = useWindowDimensions()

  const userRole = useSelector(state => state.headerReducer.role)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const data = useSelector(state => state.meterReducer.metersList)

  const idMeasurement = useSelector(state => state.meterReducer.measurement_id)
  const selectedMeters = useSelector(state => state.meterReducer.selectedMeters)

  const methods = useSelector(state => state.meterReducer.filterParams.selectedMethods)
  const customers = useSelector(state => state.meterReducer.filterParams.selectedCustomers)
  const titles = useSelector(state => state.meterReducer.filterParams.selectedTitles)
  const linkSI = useSelector(state => state.meterReducer.filterParams.isLinkSI)
  const pdf = useSelector(state => state.meterReducer.filterParams.isPDF)

  const deleteMeters = async () => {
    const { status, data } = await R.deleteMeters(idMeasurement, {
      ids: selectedMeters,
    })

    if (status === 200) {
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Приборы были успешно удалены'))

      dispatch(refreshMeters())
    }

    false && console.log(data)
  }

  const handleShowClick = param => {
    dispatch(setShow(true))

    const go = () => {
      navigate(`/metriva/meters/${param.row.id}`)
      dispatch(setShow(false))
    }

    ;(async () => {
      const { data } = await R.getMeterItem(`${idMeasurement}/${param.row.id}`, go)

      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'manufacturer',
            label: data.data.manufacturer.name,
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'city',
            label: data.data.manufacturer_cities,
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'country',
            label: data.data.manufacturer_countries,
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'numberRegistry',
            label: data.data.fif_number,
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'nameSi',
            label: data.data.name_si,
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'typeSi',
            label: data.data.type_si,
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'link',
            label: data.data.type_link,
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'pdf',
            label: data.data.pdf,
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'image',
            label: data.data.images ? data.data.images : [],
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'variants',
            label: data.data.modifications,
          }),
        )
    })()
  }

  const handleShowClickMobile = param => {
    const go = () => {
      navigate(`/metriva/meters/${param}`)
    }

    ;(async () => {
      const { data } = await R.getMeterItem(`${idMeasurement}/${param}`, go)

      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'manufacturer',
            label: data.data.manufacturer.name,
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'city',
            label: data.data.manufacturer_cities,
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'country',
            label: data.data.manufacturer_countries,
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'numberRegistry',
            label: data.data.fif_number,
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'nameSi',
            label: data.data.name_si,
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'typeSi',
            label: data.data.type_si,
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'link',
            label: data.data.type_link,
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'pdf',
            label: data.data.pdf,
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'image',
            label: data.data.images ? data.data.images : [],
          }),
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: 'variants',
            label: data.data.modifications,
          }),
        )
    })()
  }

  const handleEditClick = param => {
    dispatch(setShow(true))

    const go = () => {
      navigate(`/metriva/meters/${param}/edit_water`)
      dispatch(setShow(false))
    }

    ;(async () => {
      const { data } = await R.getMeterItem(`${idMeasurement}/${param}`, go)

      // ----------------------------------------------------------------
      // техдолг - объединить все в initial после обновления прода
      // ----------------------------------------------------------------

      data.data &&
        dispatch(
          changeMeterWaterInitial({
            meterIndex: data.data.id,
            checkMethod: data.data.methods.map(m => m.id),
            numberRegistry: data.data.fif_number,
            number: '',
            nameSi: data.data.name_si,
            typeSi: data.data.type_si,
            country: data.data.manufacturer_countries,
            cityFactory: data.data.manufacturer_cities,
            manufacturerFactory: data.data.manufacturer.name,
            mpiText: '',
            hot: data.data.mpi_hot ? data.data.mpi_hot : '',
            cold: data.data.mpi_cold ? data.data.mpi_cold : '',
            diameter: [],
            qLimitMinCold: data.data.q_min_limit_cold ? data.data.q_min_limit_cold : '',
            qLimitMaxCold: data.data.q_max_limit_cold ? data.data.q_max_limit_cold : '',
            qLimitMinHot: data.data.q_min_limit_hot ? data.data.q_min_limit_hot : '',
            qLimitMaxHot: data.data.q_max_limit_hot ? data.data.q_max_limit_hot : '',
            fgisLink: data.data.type_link,
            pdf: null,
            imgs: data.data.images,
            options: data.data.additionals,
            mods: data.data.modifications,
            mod: {
              title: '',
              diameter: '',
              koeff: '',
              water: null,
              vPositionQmin: '',
              vPositionQt: '',
              vPositionQmax: '',
              hPositionQmin: '',
              hPositionQt: '',
              hPositionQmax: '',
              additionals: [],
            },
          }),
        )
    })()
  }

  const handleDelete = async meterId => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/meters/${idMeasurement}/${meterId}`
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      }
      const response = await api.delete(apiUrl, { headers })
      if (response.data && response.data.message) {
        dispatch(setMessage(response.data.message))
      }
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))

      dispatch(setOpen(false))
      onDelete()
    } catch (error) {
      console.error(error)
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Ошибка на сервере.'))
    }
  }

  let colData =
    width > 1050
      ? [
          {
            field: 'numberRegistry',
            headerName: 'Номер в реестре ФИФ',
          },
          {
            field: 'nameSi',
            headerName: 'Наименование',
          },
          {
            field: 'manufacturerName',
            headerName: 'Завод производитель',
          },
          {
            field: 'typeLink',
            headerName: 'Ссылка на прибор',
            renderCell: params => {
              return (
                <span
                  style={{ color: 'red' }}
                  onClick={event => {
                    event.stopPropagation()

                    false && console.log(params.row)
                  }}
                >
                  <a href={params.row.typeLink} target='_blank' style={{ color: '#0093FD' }}>
                    Ссылка на ФГИС Аршин
                  </a>
                </span>
              )
            },
          },
        ]
      : [
          {
            field: 'numberRegistry',
            headerName: 'Номер в реестре ФИФ',
          },
          {
            field: 'nameSi',
            headerName: 'Наименование',
          },
          {
            field: 'manufacturerName',
            headerName: 'Завод производитель',
          },
          {
            field: 'typeLink',
            headerName: 'Ссылка на прибор',
            renderCell: params => {
              return (
                <span>
                  <a href={params.row.typeLink} target='_blank' style={{ color: '#0093FD' }}>
                    Ссылка на ФГИС Аршин
                  </a>
                </span>
              )
            },
          },
        ]

  if (width > 1050) {
    colData.splice(8, 0, {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: item => {
        const localItem = item

        return [
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              gap: '22px',
            }}
          >
            {userRole === 'super' && width > 1050 && (
              <Tooltip title='Редактировать' placement='top'>
                <PencilIcon onClick={() => handleEditClick(localItem.id)} />
              </Tooltip>
            )}
            {userRole === 'super' && width > 1050 && (
              <Tooltip title='Редактировать' placement='top'>
                <PencilIcon onClick={() => handleEditClick(localItem.id)} />
              </Tooltip>
            )}
            {false && (
              <DeleteIcon
                onClick={() => {
                  dispatch(setOpen(true))
                  dispatch(setTitle('Вы действительно хотите удалить прибор?'))
                  dispatch(setChildren('Отменить действие будет уже невозможно.'))
                  dispatch(setConfirmAction(() => handleDelete(localItem.id)))
                }}
              />
            )}
            {/* <Tooltip title='Скачать PDF с описанием типа' placement='top'>
              <PdfDownloadButton
                measurementId={idMeasurement}
                meterId={localItem.id}
                title={localItem.row.numberRegistry}
              />
            </Tooltip> */}
          </div>,
        ]
      },
    })
  }

  const DinamicActions = (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        height: '100%',
        gap: '10px',
      }}
    >
      <Button
        color='text'
        startIcon={
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M2.75 6.16667C2.75 5.70644 3.09538 5.33335 3.52143 5.33335L6.18567 5.3329C6.71502 5.31841 7.18202 4.95482 7.36214 4.41691C7.36688 4.40277 7.37232 4.38532 7.39185 4.32203L7.50665 3.94993C7.5769 3.72179 7.6381 3.52303 7.72375 3.34536C8.06209 2.64349 8.68808 2.1561 9.41147 2.03132C9.59457 1.99973 9.78848 1.99987 10.0111 2.00002H13.4891C13.7117 1.99987 13.9056 1.99973 14.0887 2.03132C14.8121 2.1561 15.4381 2.64349 15.7764 3.34536C15.8621 3.52303 15.9233 3.72179 15.9935 3.94993L16.1083 4.32203C16.1279 4.38532 16.1333 4.40277 16.138 4.41691C16.3182 4.95482 16.8778 5.31886 17.4071 5.33335H19.9786C20.4046 5.33335 20.75 5.70644 20.75 6.16667C20.75 6.62691 20.4046 7 19.9786 7H3.52143C3.09538 7 2.75 6.62691 2.75 6.16667Z'
              fill='#4B5962'
            />
            <path
              d='M11.6068 21.9998H12.3937C15.1012 21.9998 16.4549 21.9998 17.3351 21.1366C18.2153 20.2734 18.3054 18.8575 18.4855 16.0256L18.745 11.945C18.8427 10.4085 18.8916 9.6402 18.45 9.15335C18.0084 8.6665 17.2628 8.6665 15.7714 8.6665H8.22905C6.73771 8.6665 5.99204 8.6665 5.55047 9.15335C5.10891 9.6402 5.15777 10.4085 5.25549 11.945L5.515 16.0256C5.6951 18.8575 5.78515 20.2734 6.66534 21.1366C7.54553 21.9998 8.89927 21.9998 11.6068 21.9998Z'
              fill='#4B5962'
            />
          </svg>
        }
        label='Полностью удалить'
        onClick={deleteMeters}
        styles={{
          padding: 0,
          color: 'inherit',
          minWidth: '50px',
          paddingLeft: '16px',
          paddingRight: '14px',
          marginLeft: '-10px',
          fontSize: '.88rem',
        }}
      />
    </div>
  )

  useEffect(() => {
    false && console.log(data)
  }, [data])
  useEffect(() => {
    dispatch(setName('База приборов'))
  }, [])
  useEffect(() => {
    dispatch(settingsFileViewer({ value: 'open', label: false }))
  }, [])

  return (
    <React.Fragment>
      {(!data || data.length === 0) && (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              flexFlow: 'column',
            }}
          >
            <Skeleton
              sx={{ backgroundColor: '#fff' }}
              animation='wave'
              width={'100%'}
              height={66}
            />
            <Skeleton sx={{ marginTop: '-16px' }} animation='wave' width={'100%'} height={66} />
            <Skeleton
              sx={{ backgroundColor: '#fff', marginTop: '-16px' }}
              animation='wave'
              width={'100%'}
              height={66}
            />
            <Skeleton sx={{ marginTop: '-16px' }} animation='wave' width={'100%'} height={66} />
            <Skeleton
              sx={{ backgroundColor: '#fff', marginTop: '-16px' }}
              animation='wave'
              width={'100%'}
              height={66}
            />
            <Skeleton sx={{ marginTop: '-16px' }} animation='wave' width={'100%'} height={66} />
          </div>
        </React.Fragment>
      )}
      {data && data.length > 0 && (
        <React.Fragment>
          {width > 1050 ? (
            <Table
              colData={colData}
              rowData={data
                .filter(rowData => rowData.manufacturer.name.indexOf(customers) !== -1)
                .filter(rowData => rowData.name_si.indexOf(titles) !== -1)
                .filter(rowData => {
                  if (methods.length === 0) return rowData
                  if (methods.length > 0) {
                    if (
                      rowData.methods
                        .map(method => method.name)
                        .some(method => methods.includes(method))
                    ) {
                      return rowData
                    }
                  }
                })
                .filter(rowData => {
                  if (linkSI === null) return rowData
                  if (+linkSI === 0) return !!!rowData.type_link ? rowData : null
                  if (+linkSI === 1) return rowData.type_link ? rowData : null
                })
                .filter(rowData => {
                  if (pdf === null) return rowData
                  if (+pdf === 0) return !!!rowData.pdf_id ? rowData : null
                  if (+pdf === 1) return rowData.pdf_id ? rowData : null
                })
                .map(item => ({
                  id: item.id,
                  numberRegistry: item.fif_number,
                  nameSi: item.name_si,
                  manufacturerName: item.manufacturer.name,
                  typeLink: item.type_link,
                }))}
              hasCheckboxSelection={width > 1050 && userRole === 'super' ? true : false}
              rowCallback={handleShowClick}
              addButtonCallback={() => {
                navigate('/metriva/meters/create_water_base')
              }}
              showToolbar={true}
              noCreating={false}
              actions={{
                static: null,
                dinamic: selectedMeters && selectedMeters.length > 0 ? DinamicActions : null,
              }}
              filtersVariant={'server'}
              setIsDrawerOpenServer={() => {
                dispatch(setOpenRightContainer(true))
              }}
              rowSelectionCallback={array => {
                dispatch(setSelectedMeters(array))
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />
          ) : (
            <TableMobile
              controlIconButton={'getPDF'}
              rowCallback={handleShowClickMobile}
              rowCallbackParams={''}
              data={data
                .filter(rowData => rowData.manufacturer.name.indexOf(customers) !== -1)
                .filter(rowData => rowData.name_si.indexOf(titles) !== -1)
                .filter(rowData => {
                  if (methods.length === 0) return rowData
                  if (methods.length > 0) {
                    if (
                      rowData.methods
                        .map(method => method.name)
                        .some(method => methods.includes(method))
                    ) {
                      return rowData
                    }
                  }
                })
                .filter(rowData => {
                  if (linkSI === null) return rowData
                  if (+linkSI === 0) return !!!rowData.type_link ? rowData : null
                  if (+linkSI === 1) return rowData.type_link ? rowData : null
                })
                .filter(rowData => {
                  if (pdf === null) return rowData
                  if (+pdf === 0) return !!!rowData.pdf_id ? rowData : null
                  if (+pdf === 1) return rowData.pdf_id ? rowData : null
                })
                .map(item => {
                  return [
                    ['ID', item.id],
                    ['args', [item.pdf_id]],
                    ['№ СИ в госреестре', item.fif_number],
                    ['Наименование', item.name_si],
                    ['Изготовитель', item.manufacturer.name],
                    [
                      'Утвержденные типы СИ',
                      <a
                        href={item.type_link}
                        target='_blank'
                        style={{ color: 'rgb(0, 132, 226)' }}
                      >
                        Ссылка на ФГИС Аршин
                      </a>,
                    ],
                  ]
                })}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Tab
