import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'

export const StyledButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    fontFamily: "'Wix Madefor Display',sans-serif",
    minWidth: '200px',
    borderRadius: '12px',
    boxShadow: 'none',
    textTransform: 'none',
    margin: 0,
    height: '54.4px',
    fontSize: '15px',
    fontWeight: 500,
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  '&.primary': {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.background.default,
    border: 'none',
    transition: 'ease 0.5s',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.background.light,
      color: theme.palette.background.default,
    },
    ':hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  '&.secondary': {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.text.secondary}`,
    transition: 'ease 0.5s',
    '&:hover': {
      opacity: 0.7,
    },
    '&.Mui-disabled': {
      border: `1px solid ${theme.palette.background.light}`,
      color: theme.palette.background.light,
    },
  },
  '&.text': {
    color: theme.palette.text.dark,
    border: 'none',
    transition: 'ease 0.5s',
    padding: '16px',
    backgroundColor: 'transparent',
    height: '40px',
    '&:hover': {
      opacity: 0.7,
      backgroundColor: 'transparent',
    },
  },
  '&.error': {
    backgroundColor: theme.palette.background.error,
    color: theme.palette.background.default,
    border: 'none',
    transition: 'ease 0.5s',
    fontStyle: 'normal',
    '&:hover': {
      opacity: 0.7,
    },
  },
  '&.secondary-error': {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.background.error,
    border: `1px solid ${theme.palette.background.error}`,
    transition: 'ease 0.5s',
    '&:hover': {
      opacity: 0.7,
    },
  },
}))
