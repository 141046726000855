const StarIcon = ({ color = '#4B5962', width = 25, height = 25, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 25 25'
      onClick={onClick}
    >
      <path
        d='M9.40316 5.6408C10.6698 3.36855 11.3031 2.23242 12.25 2.23242C13.1969 2.23242 13.8302 3.36855 15.0968 5.6408L15.4245 6.22865C15.7845 6.87436 15.9644 7.19721 16.2451 7.41023C16.5257 7.62325 16.8751 7.70232 17.5741 7.86047L18.2105 8.00445C20.6701 8.56098 21.9 8.83924 22.1926 9.78015C22.4852 10.7211 21.6468 11.7015 19.9699 13.6623L19.5361 14.1696C19.0596 14.7269 18.8213 15.0055 18.7141 15.3501C18.607 15.6948 18.643 16.0665 18.715 16.81L18.7806 17.4868C19.0341 20.103 19.1609 21.4111 18.3949 21.9926C17.6288 22.5742 16.4773 22.044 14.1743 20.9836L13.5785 20.7093C12.9241 20.4079 12.5969 20.2573 12.25 20.2573C11.9031 20.2573 11.5759 20.4079 10.9215 20.7093L10.3257 20.9836C8.02268 22.044 6.87118 22.5742 6.10515 21.9926C5.33912 21.4111 5.46588 20.103 5.7194 17.4868L5.78498 16.81C5.85703 16.0665 5.89305 15.6948 5.78586 15.3501C5.67868 15.0055 5.44043 14.7269 4.96392 14.1696L4.5301 13.6623C2.85325 11.7015 2.01482 10.7211 2.30742 9.78015C2.60002 8.83924 3.82986 8.56098 6.28954 8.00445L6.92589 7.86047C7.62485 7.70232 7.97433 7.62325 8.25494 7.41023C8.53555 7.19721 8.71553 6.87436 9.07547 6.22866L9.40316 5.6408Z'
        fill={color}
      />
    </svg>
  )
}

export default StarIcon
