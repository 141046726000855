// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import CheckCircleIcon from '../../../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../../../img/icon/VisibilityToggleIcon'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Feedback from '../../LocalElements/Feedback/Feedback'
import R from '../../../../services/app/request.service'
import { changeArchived } from '../../../../store/slices/app/views/journals'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import { refreshArchives } from '../../../../store/slices/app/controlers/updater'
import Input from '../../../../ui/input/index.tsx'
import Button from '../../../../ui/button/index'
import { Typography } from '@mui/material'
import { Wrapper, Frame, Content, ContentFrame, LabelFrame } from '../style'

const Edit = () => {
  const { entry_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userRole = useSelector(state => state.headerReducer.role)
  const show = useSelector(state => state.feedbackReducer.show)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const accountNumber = useSelector(state => state.journalReducer.archived.accountNumber)
  const createdByName = useSelector(state => state.journalReducer.archived.createdByName)
  const issueDate = useSelector(state => state.journalReducer.archived.issueDate)
  const returnDate = useSelector(state => state.journalReducer.archived.returnDate)

  const handleSave = async () => {
    const changeData = {
      account_number: accountNumber,
      created_by_name: createdByName,
      issue_date: issueDate,
      return_date: returnDate,
    }

    const { status } = await R.editArchiveJournals(companyId, entry_id, changeData)

    if (status === 200) {
      dispatch(setMessage('Запись успешно отредактирована'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshArchives())
      navigate('/metriva/journals/archive')
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Запись не отредактирована - ошибка валидации'))
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/journals/archive')
  }

  useEffect(() => {
    ;(async () => {
      if (companyId) {
        const {
          data: { data: editJournalData },
        } = await R.getEditArchiveJournals(companyId, entry_id)

        dispatch(
          changeArchived({
            value: 'accountNumber',
            label: editJournalData.account_number ? editJournalData.account_number.toString() : '',
          }),
        )
        dispatch(
          changeArchived({
            value: 'createdByName',
            label: editJournalData.created_by_name ? editJournalData.created_by_name : '',
          }),
        )
        dispatch(
          changeArchived({
            value: 'issueDate',
            label: editJournalData.issue_date ? editJournalData.issue_date : '',
          }),
        )
        dispatch(
          changeArchived({
            value: 'returnDate',
            label: editJournalData.return_date ? editJournalData.return_date : '',
          }),
        )
      }
    })()
  }, [companyId, entry_id])

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <Wrapper>
          <Frame onClick={navigateToProtocols}>
            <i
              className='fas fa-arrow-left icon_back'
              style={{ width: '30px', height: '30px', marginRight: '6px' }}
            />
            <Typography variant='subtitle2'>Все записи документа</Typography>
          </Frame>

          <Typography variant='h2'>Редактирование архивной записи</Typography>

          <Content>
            <Typography variant='h2'>Информация о журнале</Typography>

            <ContentFrame>
              <LabelFrame sx={{ width: '50%' }}>
                <Input
                  label={'Учетный номер документа'}
                  placeholder={'Пример ввода - 00-03'}
                  type={'text'}
                  value={accountNumber}
                  actions={{
                    change: param =>
                      dispatch(
                        changeArchived({
                          value: 'accountNumber',
                          label: param.toString(),
                        }),
                      ),
                  }}
                />
              </LabelFrame>
              <LabelFrame sx={{ width: '50%' }}>
                <Input
                  label={'ФИО передавшего документ'}
                  placeholder={'Введите данные передавшего'}
                  type={'text'}
                  value={createdByName}
                  actions={{
                    change: param =>
                      dispatch(
                        changeArchived({
                          value: 'createdByName',
                          label: param,
                        }),
                      ),
                  }}
                />
              </LabelFrame>
            </ContentFrame>

            <ContentFrame>
              <LabelFrame sx={{ width: '50%' }}>
                <Typography variant='subtitle2' sx={{ display: 'flex', alignItems: 'center' }}>
                  Дата выдачи из архива
                  {!!issueDate ? (
                    <CheckCircleIcon
                      sx={{
                        color: '#63E6BE',
                        marginLeft: '8px',
                        marginTop: '0px',
                      }}
                    />
                  ) : (
                    <VisibilityToggleIcon
                      sx={{
                        color: '#FFD43B',
                        marginLeft: '8px',
                        marginTop: '0px',
                      }}
                    />
                  )}
                </Typography>
                <DatePicker
                  selectedDate={issueDate}
                  dateChange={date =>
                    dispatch(
                      changeArchived({
                        value: 'issueDate',
                        label: date.format('YYYY-MM-DD'),
                      }),
                    )
                  }
                />
              </LabelFrame>

              <LabelFrame sx={{ width: '50%' }}>
                <Typography variant='subtitle2' sx={{ display: 'flex', alignItems: 'center' }}>
                  Дата возврата в архив
                  {!!returnDate ? (
                    <CheckCircleIcon
                      sx={{
                        color: '#63E6BE',
                        marginLeft: '8px',
                        marginTop: '0px',
                      }}
                    />
                  ) : (
                    <VisibilityToggleIcon
                      sx={{
                        color: '#FFD43B',
                        marginLeft: '8px',
                        marginTop: '0px',
                      }}
                    />
                  )}
                </Typography>
                <DatePicker
                  selectedDate={returnDate}
                  dateChange={date =>
                    dispatch(
                      changeArchived({
                        value: 'returnDate',
                        label: date.format('YYYY-MM-DD'),
                      }),
                    )
                  }
                />
              </LabelFrame>
            </ContentFrame>
          </Content>

          <Button onClick={handleSave} label='Редактировать запись' color='primary' fullWidth />
        </Wrapper>
      )}
    </main>
  )
}

export default Edit
