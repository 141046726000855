// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState, useRef } from 'react'
import { DataGrid, ruRU, GridOverlay, GridRowModes } from '@mui/x-data-grid'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'

import CustomToolbar from './CustomToolbar'

const getTextWidth = (text, font) => {
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')

  context.font = font
  const metrics = context.measureText(text)

  return metrics.width
}

const extractTextFromReactNode = node => {
  if (typeof node === 'string') {
    return node
  } else if (React.isValidElement(node)) {
    return React.Children.toArray(node.props.children).map(extractTextFromReactNode).join('')
  } else if (Array.isArray(node)) {
    return node.map(extractTextFromReactNode).join('')
  }
  return ''
}

const calculateColumnWidth = (column, rows, font) => {
  const headerText =
    typeof column.headerName === 'string'
      ? column.headerName
      : extractTextFromReactNode(column.headerName)
  const headerWidth = getTextWidth(headerText, font) + 66

  let maxContentWidth = 0
  rows.forEach(row => {
    const value = row[column.field]
    const text = value ? value.toString() : ''
    const contentWidth = getTextWidth(text, font) + 66

    if (contentWidth > maxContentWidth) {
      maxContentWidth = contentWidth
    }
  })

  const maxWidth = Math.max(headerWidth, maxContentWidth)

  return Math.min(maxWidth, 800)
}

const Table = props => {
  const {
    colData,
    rowData,
    actions = {
      static: null,
      dinamic: null,
    },
    noCreating = false,
    rowCallback = () => {},
    rowSelectionCallback = () => {},
    addButtonCallback = () => {},
    searchParams = { query: null, builder: () => {} },
    hasCheckboxSelection,
    hideFooter,
    getRowHeight,
    initialState,
    rowModesModel,
    setRowModesModel,
    showToolbar = false,
    noRowsText = 'Информация отсутствует',
    filtersVariant = 'client',
    setIsDrawerOpenServer,
  } = props

  const locale = createTheme(ruRU)

  const [selectedRows, setSelectedRows] = useState([])
  const [filters, setFilters] = useState({})
  const [filteredRows, setFilteredRows] = useState(rowData)
  const [columns, setColumns] = useState([])

  const containerRef = useRef(null)
  const [containerWidth, setContainerWidth] = useState(0)

  useEffect(() => {
    if (!containerRef.current) return

    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setContainerWidth(entry.contentRect.width)
      }
    })

    resizeObserver.observe(containerRef.current)

    setContainerWidth(containerRef.current.offsetWidth)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  useEffect(() => {
    rowSelectionCallback(selectedRows)
  }, [selectedRows])

  useEffect(() => {
    let filtered = rowData

    Object.keys(filters).forEach(field => {
      if (field !== 'query') {
        const value = filters[field]
        if (value) {
          const isRangeFilter = Array.isArray(value) && value.length === 2

          filtered = filtered.filter(row => {
            const cellValue = row[field]

            if (isRangeFilter) {
              const [min, max] = value
              return typeof cellValue === 'number' && cellValue >= min && cellValue <= max
            } else {
              const cellValueString = cellValue != null ? String(cellValue).toLowerCase() : ''
              const filterValueString = String(value).toLowerCase()

              console.log(cellValueString)

              return ~cellValueString.indexOf(filterValueString)
            }
          })
        }
      }
    })

    const queryValue = filters['query']

    filtered = filtered.filter(row => {
      if (
        Object.values(row).some(
          field =>
            (typeof field === 'string' || typeof field === 'number') &&
            field
              ?.toString()
              .toLowerCase()
              .includes(queryValue?.toLowerCase() || ''),
        )
      ) {
        return row
      }
    })

    setFilteredRows(filtered)
  }, [filters, rowData])

  useEffect(() => {
    const font = "'Wix Madefor Display', sans-serif"

    const actionColumns = colData.filter(col => col.field === 'actions')
    const otherColumns = colData.filter(col => col.field !== 'actions')

    const calculatedColumns = otherColumns.map(col => ({
      ...col,
      filterable: col.field !== 'actions',
      width: calculateColumnWidth(col, rowData, `15px ${font}`),
      minWidth: 100,
      maxWidth: 600,
      flex: 0,
      // ...(col.type === 'boolean'
      //   ? {
      //       renderCell: params =>
      //         params.value ? (
      //           <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      //             <CheckCircleIcon />
      //           </div>
      //         ) : (
      //           <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      //             <ExitIcon />
      //           </div>
      //         ),
      //     }
      //   : {}),
    }))

    actionColumns.forEach(col => {
      calculatedColumns.push({
        ...col,
        width: 300,
        minWidth: 300,
        maxWidth: 300,
        flex: 0,
        sortable: false,
        filterable: false,
      })
    })

    if (containerWidth > 0) {
      const totalColumnsWidth = calculatedColumns.reduce((acc, col) => acc + (col.width || 0), 0)

      const availableWidth = containerWidth - 300

      if (totalColumnsWidth < availableWidth) {
        let widestColumn = calculatedColumns
          .filter(col => col.field !== 'actions')
          .reduce(
            (prev, current) => (prev.width > current.width ? prev : current),
            calculatedColumns[0],
          )

        const missingWidth = availableWidth - (totalColumnsWidth - widestColumn.width)

        const updatedColumns = calculatedColumns.map(col => {
          if (col.field === widestColumn.field) {
            return { ...col, width: col.width + missingWidth }
          }
          return col
        })

        setColumns(updatedColumns)
      } else {
        setColumns(calculatedColumns)
      }
    } else {
      setColumns(calculatedColumns)
    }
  }, [colData, rowData, containerWidth])

  useEffect(() => {
    return () => {
      setFilters({})
    }
  }, [])

  return (
    <ThemeProvider theme={locale}>
      <Box ref={containerRef} sx={{ minHeight: 155, width: '100%' }}>
        <DataGrid
          autoHeight
          slots={{
            toolbar: showToolbar === false ? null : CustomToolbar,
            noRowsOverlay: () => (
              <GridOverlay>
                <Box
                  sx={{
                    mt: 1,
                    color: '#132532',
                    fontSize: '18.8px',
                    textAlign: 'center',
                    fontWeight: '400',
                    letterSpacing: '0.4px',
                  }}
                >
                  {noRowsText}
                </Box>
              </GridOverlay>
            ),
          }}
          slotProps={{
            toolbar: {
              rowData: rowData,
              columns: colData,
              filters: filters,
              actions: actions,

              noCreating: noCreating,
              setFilters: setFilters,
              searchParams: searchParams,
              filtersVariant: filtersVariant,
              selectedRowCount: selectedRows.length,

              addButtonCallback: addButtonCallback,
              setIsDrawerOpenServer: setIsDrawerOpenServer,
            },
          }}
          rows={filteredRows}
          columns={columns}
          initialState={initialState}
          pageSizeOptions={[10, 20, 30, 40, 50]}
          checkboxSelection={hasCheckboxSelection}
          rowSelectionModel={selectedRows}
          rowModesModel={rowModesModel}
          onRowModesModelChange={setRowModesModel}
          onRowSelectionModelChange={setSelectedRows}
          disableColumnMenu
          onRowClick={rowCallback && rowCallback}
          hideFooter={hideFooter ? hideFooter : false}
          getRowHeight={
            rowModesModel
              ? params => (rowModesModel[params.id]?.mode === GridRowModes.Edit ? 60 : '')
              : getRowHeight
          }
          sx={{
            width: '100%',
            borderRadius: '12px',
            padding: '10px',
            color: '#132532',
            marginTop: '12px',
            backgroundColor: '#ffffff',
            border: '1px solid #d7ddea',
            overflow: 'auto',
            display: 'flex',
            '& .MuiDataGrid-columnHeaderTitle': {
              whiteSpace: 'normal',
              lineHeight: '20px',
              wordWrap: 'break-word',
            },
            '& .MuiDataGrid-cell:hover': {
              cursor: 'default',
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
              wordWrap: 'break-word',
            },
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#F8F9FC',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell--editing:focus-within': {
              outline: 'none !important',
            },
            '& .MuiDataGrid-cell--editing': {
              borderRadius: '0px',
              marginRight: '0px',
              boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.1) !important',
            },
          }}
        />
      </Box>
    </ThemeProvider>
  )
}

export default Table
