import { StyledRadioGroup, StyledFormControlLabel, StyledRadio } from './styles'

const RadioButton = ({
  value = '',
  onChange,
  trueLabel,
  falseLabel,
  trueValue,
  falseValue,
  trueDisabled = false,
  falseDisabled = false,
}) => {
  return (
    <StyledRadioGroup
      row
      aria-labelledby='demo-row-radio-buttons-group-label'
      name='row-radio-buttons-group'
      value={value}
      onChange={onChange}
    >
      <StyledFormControlLabel
        value={trueValue}
        control={<StyledRadio />}
        label={trueLabel}
        disabled={trueDisabled}
      />
      <StyledFormControlLabel
        value={falseValue}
        control={<StyledRadio />}
        label={falseLabel}
        disabled={falseDisabled}
      />
    </StyledRadioGroup>
  )
}

export default RadioButton
