import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  open: false,
  title: null,
  child: null,
}

export const bottomContainerReducer = createSlice({
  name: 'bottomContainerReducer',
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload
    },
    setTitle: (state, action) => {
      state.title = action.payload
    },
    setChild: (state, action) => {
      state.child = action.payload
    },
  },
})

export const { setOpen, setTitle, setChild } = bottomContainerReducer.actions
export default bottomContainerReducer.reducer
