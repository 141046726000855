const InfoCircle = () => {
  return (
    <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 13C9.31371 13 12 10.3137 12 7C12 3.68629 9.31371 1 6 1C2.68629 1 0 3.68629 0 7C0 10.3137 2.68629 13 6 13ZM5.641 7.77891H6.52197C6.52197 7.56011 6.57091 7.37585 6.6688 7.22614C6.76669 7.07644 6.9567 6.92097 7.23884 6.75975C7.60159 6.54094 7.87222 6.30487 8.05072 6.05152C8.23497 5.79816 8.3271 5.51026 8.3271 5.18782C8.3271 4.8481 8.23497 4.5458 8.05072 4.28094C7.87222 4.01031 7.62751 3.79726 7.31657 3.6418C7.00564 3.48058 6.65153 3.39996 6.25423 3.39996C5.82238 3.39996 5.41068 3.49785 5.01914 3.69362C4.63335 3.88939 4.29363 4.17153 3.99997 4.54004L4.75139 5.28282C4.96444 4.96614 5.19764 4.73582 5.45099 4.59187C5.70434 4.44216 5.96057 4.3673 6.21968 4.3673C6.50758 4.3673 6.74078 4.44504 6.91927 4.6005C7.09777 4.75597 7.18702 4.95462 7.18702 5.19645C7.18702 5.35192 7.14383 5.49299 7.05746 5.61967C6.97685 5.74634 6.84442 5.87302 6.66016 5.99969C6.38378 6.18971 6.16498 6.37396 6.00375 6.55246C5.84829 6.7252 5.74177 6.90946 5.68419 7.10523C5.63236 7.301 5.61797 7.52556 5.641 7.77891ZM6.5997 9.60131C6.7379 9.46312 6.80699 9.29902 6.80699 9.109C6.80699 8.91323 6.7379 8.74913 6.5997 8.6167C6.46151 8.47851 6.29165 8.40941 6.09012 8.40941C5.88859 8.40941 5.71873 8.47851 5.58054 8.6167C5.44235 8.74913 5.37325 8.91323 5.37325 9.109C5.37325 9.29902 5.44235 9.46312 5.58054 9.60131C5.71873 9.73375 5.88859 9.79996 6.09012 9.79996C6.29165 9.79996 6.46151 9.73375 6.5997 9.60131Z'
        fill='#0084E2'
      />
    </svg>
  )
}

export default InfoCircle
