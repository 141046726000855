import React, { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'

import Journals from '../app/pages/Journals/Journals'

import JournalsArchive from '../app/pages/Journals/JournalsArchive'
import EditArchive from '../app/components/Journals/JournalArchive/Edit'

import JournalsArchiveEntries from '../app/pages/Journals/JournalsArchiveEntries'

import AddTickets from '../app/components/Journals/JournalTickets/Add'
import EditTickets from '../app/components/Journals/JournalTickets/Edit'

import AddPersonalMetrolog from '../app/components/Journals/JournalPersonal/Add'
import EditPersonalMetrolog from '../app/components/Journals/JournalPersonal/Edit'

import EditJournalConditionsCase from '../app/components/Journals/JournalConditionsCases/Edit'

import AddReceived from '../app/components/Journals/JournalReceivedForVerification/Add'
import EditReceived from '../app/components/Journals/JournalReceivedForVerification/Edit'

import AddIssueCase from '../app/components/Journals/JournalIssueCase/Add'
import EditIssueCase from '../app/components/Journals/JournalIssueCase/Edit'

import AddKitVerifications from '../app/components/Journals/JournalKitVerificationsGraph/create-and-edit/Add'
import EditKitVerifications from '../app/components/Journals/JournalKitVerificationsGraph/create-and-edit/Edit'

import EditKitPassports from '../app/components/Journals/JournalsKitPassports/edit/index'

import NotForMobile from '../app/pages/NotForMobile'
import useWindowDimensions from '../app/hooks/useWindowDimensions'

const RouteJournals: React.FC = (): ReactElement => {
  const { width } = useWindowDimensions()

  return (
    <Routes>
      <Route index element={width > 1050 ? <Journals /> : <NotForMobile />} />
      <Route path='archive' element={width > 1050 ? <JournalsArchive /> : <NotForMobile />} />
      <Route
        path=':entry_id/edit_archives'
        element={width > 1050 ? <EditArchive /> : <NotForMobile />}
      />

      {/* ---------------------------------------------------------------- */}
      {/* архивные записи конкретных журналов */}
      {/* ---------------------------------------------------------------- */}

      <Route
        path='archive_entries'
        element={width > 1050 ? <JournalsArchiveEntries /> : <NotForMobile />}
      />

      {/* ---------------------------------------------------------------- */}
      {/* журнал регистрации условий хранения - редактирование */}
      {/* ---------------------------------------------------------------- */}

      <Route
        path=':journal_id/edit_conditions-of-cases'
        element={width > 1050 ? <EditJournalConditionsCase /> : <NotForMobile />}
      />

      {/* ---------------------------------------------------------------- */}
      {/* журнал регистрации заявок - добавление и редактирование */}
      {/* ---------------------------------------------------------------- */}

      <Route
        path='create_line_tickets'
        element={width > 1050 ? <AddTickets /> : <NotForMobile />}
      />
      <Route
        path=':journal_id/edit_tickets'
        element={width > 1050 ? <EditTickets /> : <NotForMobile />}
      />

      {/* ---------------------------------------------------------------- */}
      {/* персональный журнал метролога - добавление и редактирование */}
      {/* ---------------------------------------------------------------- */}

      <Route
        path='create_line_personal_master-poveritel'
        element={width > 1050 ? <AddPersonalMetrolog /> : <NotForMobile />}
      />
      <Route
        path=':journal_id/edit_personal_master-poveritel'
        element={width > 1050 ? <EditPersonalMetrolog /> : <NotForMobile />}
      />

      {/* ---------------------------------------------------------------- */}
      {/* журнал выдачи средств поверки - добавление и редактирование */}
      {/* ---------------------------------------------------------------- */}

      <Route
        path='create_line_issue_case'
        element={width > 1050 ? <AddIssueCase /> : <NotForMobile />}
      />
      <Route
        path=':journal_id/edit_issue_case'
        element={width > 1050 ? <EditIssueCase /> : <NotForMobile />}
      />

      {/* ---------------------------------------------------------------- */}
      {/* журнал учета поступивших на поверку си - добавление */}
      {/* ---------------------------------------------------------------- */}

      <Route
        path='create_line_received'
        element={width > 1050 ? <AddReceived /> : <NotForMobile />}
      />

      <Route
        path=':journal_id/edit_received'
        element={width > 1050 ? <EditReceived /> : <NotForMobile />}
      />

      {/* ---------------------------------------------------------------- */}
      {/* график поверки средств измерений и контроля */}
      {/* ---------------------------------------------------------------- */}

      <Route
        path='create_line_kit_verifications'
        element={width > 1050 ? <AddKitVerifications /> : <NotForMobile />}
      />

      <Route
        path=':journal_id/edit_kit_verifications'
        element={width > 1050 ? <EditKitVerifications /> : <NotForMobile />}
      />

      {/* ---------------------------------------------------------------- */}
      {/* график поверки средств измерений и контроля */}
      {/* ---------------------------------------------------------------- */}

      <Route
        path=':journal_id/edit_journals_kit_passports'
        element={width > 1050 ? <EditKitPassports /> : <NotForMobile />}
      />
    </Routes>
  )
}

export default RouteJournals
