// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Feedback from '../../LocalElements/Feedback/Feedback'
import { setShow } from '../../../../store/slices/app/comps/feedback'
import R from '../../../../services/app/request.service'
import {
  changeConditions,
  resetCreateConditions,
} from '../../../../store/slices/app/views/journals'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import { refreshConditions } from '../../../../store/slices/app/controlers/updater'
import Input from '../../../../ui/input/index.tsx'
import Button from '../../../../ui/button/index'
import { Typography } from '@mui/material'
import { Wrapper, Frame, Content, ContentFrame, LabelFrame } from '../style'

const Edit = () => {
  const { journal_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userRole = useSelector(state => state.headerReducer.role)
  const show = useSelector(state => state.feedbackReducer.show)
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const temp = useSelector(state => state.journalReducer.createConditions.temp)
  const humidity = useSelector(state => state.journalReducer.createConditions.humidity)
  const pressure = useSelector(state => state.journalReducer.createConditions.pressure)
  const conditionDate = useSelector(state => state.journalReducer.createConditions.conditionDate)

  const handleTempChange = param => {
    dispatch(changeConditions({ value: 'temp', label: param }))
  }
  const handleHumidityChange = param => {
    dispatch(changeConditions({ value: 'humidity', label: param }))
  }
  const handlePressureChange = param => {
    dispatch(changeConditions({ value: 'pressure', label: param }))
  }

  useEffect(() => {
    ;(async () => {
      dispatch(setShow(true))
      try {
        const { data: conditionsItemData } = await R.getConditionsItem(journal_id, companyId)
        const data = conditionsItemData.data

        dispatch(
          changeConditions({
            value: 'temp',
            label: data.temperature_environment,
          }),
        )

        dispatch(
          changeConditions({
            value: 'humidity',
            label: data.relative_humidity,
          }),
        )

        dispatch(
          changeConditions({
            value: 'pressure',
            label: data.atmospheric_pressure,
          }),
        )

        dispatch(
          changeConditions({
            value: 'conditionDate',
            label: data.condition_date,
          }),
        )
      } catch (error) {
        console.error(error)
      }
      dispatch(setShow(false))
    })()
  }, [journal_id, companyId, dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetCreateConditions())
    }
  }, [])

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      temperature_environment: temp,
      relative_humidity: humidity,
      atmospheric_pressure: pressure,
      condition_date: conditionDate,
    }

    if (userRole === 'super') {
      data.company_id = companyId
    }

    const { status, data: conditionsAddData } = await R.editConditions(journal_id, companyId, data)

    if (status === 200) {
      dispatch(setMessage('Запись успешно отредактирована'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshConditions())
      navigate('/metriva/journals')
    } else {
      if (conditionsAddData.errors) {
        const errors = conditionsAddData.errors
      }
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Запись не отредактирована - ошибка валидации'))
      setIsDisabledBtn(false)
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/journals')
  }

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <Wrapper>
          <Frame onClick={navigateToProtocols}>
            <i
              className='fas fa-arrow-left icon_back'
              style={{ width: '30px', height: '30px', marginRight: '6px' }}
            />
            <Typography variant='subtitle2' style={{ lineHeight: '24px' }}>
              Все записи документа
            </Typography>
          </Frame>

          <Typography variant='h2'>Журнал регистрации условий хранения средств поверки</Typography>

          <Content>
            <Typography variant='h2' style={{ marginBottom: '4px' }}>
              Информация о журнале
            </Typography>

            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Температура, ˚С'}
                  type={'text'}
                  placeholder={'20'}
                  value={temp}
                  actions={{
                    change: handleTempChange,
                  }}
                />
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Влажность, %'}
                  type={'text'}
                  placeholder={'50'}
                  value={humidity}
                  actions={{
                    change: handleHumidityChange,
                  }}
                />
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Давление, кПа'}
                  type={'text'}
                  placeholder={'90'}
                  value={pressure}
                  actions={{
                    change: handlePressureChange,
                  }}
                />
              </LabelFrame>
            </ContentFrame>
          </Content>

          <Button
            onClick={handleSave}
            label='Редактировать запись'
            disabled={isDisabledBtn}
            color='primary'
            fullWidth
          />
        </Wrapper>
      )}
    </main>
  )
}

export default Edit
