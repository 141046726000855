import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import MasterArshinSelect from '../LocalElements/Filter/MasterArshinSelect'
import Input from '../../../ui/input/index.tsx'
import { changeData } from '../../../store/slices/app/views/cases'
import { LabelFrame, ContentFrame } from '../../pages/Cases/style'

const InnerDataCase = () => {
  const dispatch = useDispatch()
  const innerName = useSelector(state => state.casesReducer.createData.innerName)
  const arshinName = useSelector(state => state.casesReducer.createData.arshinName)

  const handleInnerNameChange = param => {
    dispatch(changeData({ value: 'innerName', label: param }))
  }

  const changeMasterId = param => {
    dispatch(changeData({ value: 'arshinName', label: param }))
  }

  return (
    <ContentFrame style={{ marginBottom: '6px' }}>
      <LabelFrame sx={{ width: '50%' }}>
        <Input
          label={'Внутренний номер СП / название комплекта СП'}
          type={'text'}
          placeholder={'Впишите номер или название комплекта, например 08'}
          value={innerName}
          actions={{
            change: handleInnerNameChange,
          }}
        />
      </LabelFrame>

      <LabelFrame sx={{ width: '50%' }}>
        <MasterArshinSelect
          masterId={arshinName}
          setMasterId={changeMasterId}
          label={{
            text: 'Официальный пользователь ',
            topBg: 'white',
            bottomBg: 'white',
          }}
        />
      </LabelFrame>
    </ContentFrame>
  )
}

export default InnerDataCase
