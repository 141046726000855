// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/rules-of-hooks */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useMemo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Fab, useMediaQuery } from '@mui/material'
import R from '../../../../services/app/request.service'
import Table from '../../LocalElements/Table/Table'
import {
  refreshPersonalMetrolog,
  refreshArchives,
} from '../../../../store/slices/app/controlers/updater'
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  showBA,
  showBD,
  setBD,
  resetState,
} from '../../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import SearchQuery from '../../LocalElements/Search/SearchQuery'
import RightSideContainer from '../../LocalElements/RightSideContainer/RightSideContainer'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import useExcelCsvPdf from '../../../hooks/useExcelCsv'
import FilterIcon from '../../../../img/icon/FilterIcon'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer'
import {
  setSearchQuery,
  changePersonal,
  resetPersonal,
  setArchiveTo,
  resetArchiveFrom,
  resetArchiveTo,
} from '../../../../store/slices/app/views/journals'
import CloseIcon from '@mui/icons-material/Close'
import PencilIcon from '../../../../img/icon/PencilIcon'
import DeleteIcon from '../../../../img/icon/DeleteIcon'
import ArchiveIcon from '../../../../img/icon/ArchiveIcon'
import Csv from '../../../../img/icon/CsvIcon'
import Excel from '../../../../img/icon/Excel'
import Pdf from '../../../../img/icon/PdfIcon'
import Tooltip from '../../../../ui/tooltip/index'
import Button from '../../../../ui/button/index'
import RadioButton from '../../../../ui/radio-button/index'
import theme from '../../../../utils/theme.ts'
import { Container, CountLabel, Filter, IconFrame, Wrapper, FrameFilter } from '../style'

import { archiveTitle, archiveMessage, ModalContent } from '../archiveModal/archiveModal'

const TablePersonal = () => {
  const userRole = useSelector(state => state.headerReducer.role)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.up('lg'))
  const { handleDownload } = useExcelCsvPdf()

  const [data, setData] = useState([])
  const [localArchiveDateFrom, setLocalArchiveDateFrom] = useState('')

  const isTrusted = useSelector(state => state.headerReducer.isTrusted)
  const masterArshine = useSelector(state => state.journalReducer.masterArshine)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const searchQuery = useSelector(state => state.journalReducer.searchQuery)
  const measurementId = useSelector(state => state.journalReducer.personal.measurementId)
  const createdAtFrom = useSelector(state => state.journalReducer.personal.createdAtFrom)
  const createdAtTo = useSelector(state => state.journalReducer.personal.createdAtTo)
  const verificationDateFrom = useSelector(
    state => state.journalReducer.personal.verificationDateFrom,
  )
  const verificationDateTo = useSelector(state => state.journalReducer.personal.verificationDateTo)
  const completenessStatus = useSelector(state => state.journalReducer.personal.completenessStatus)
  const createdByRole = useSelector(state => state.journalReducer.personal.createdByRole)

  const archiveDateFrom = useSelector(state => state.journalReducer.archiveFrom)
  const archiveDateTo = useSelector(state => state.journalReducer.archiveTo)

  const entryId = useSelector(state => state.journalReducer.entryId)
  const personalData = useSelector(state => state.adminReducer.journalSettings.personalData)
  const metrologPing = useSelector(state => state.updaterReducer.personalMetrologPing)

  const count = useMemo(() => {
    let filtersCount = 0

    if (measurementId) filtersCount++
    if (createdAtFrom) filtersCount++
    if (createdAtTo) filtersCount++
    if (verificationDateFrom) filtersCount++
    if (verificationDateTo) filtersCount++
    if (completenessStatus) filtersCount++
    if (createdByRole) filtersCount++

    if (
      !measurementId &&
      !createdAtFrom &&
      !createdAtTo &&
      !verificationDateFrom &&
      !verificationDateTo &&
      !completenessStatus &&
      !createdByRole
    )
      filtersCount = 0
    return filtersCount
  }, [
    measurementId,
    createdAtFrom,
    createdAtTo,
    verificationDateFrom,
    verificationDateTo,
    completenessStatus,
    createdByRole,
  ])

  let colData = [
    {
      field: 'number',
      headerName: '№ п/п',
    },
    {
      field: 'numSi',
      headerName: 'Номер СИ в Госреестре',
    },
    {
      field: 'nameSi',
      headerName: 'Наименование владельца СИ',
    },
    {
      field: 'nameTypeSi',
      headerName: 'Наименование и тип СИ',
    },
    {
      field: 'numFactory',
      headerName: 'Заводской номер',
    },
    {
      field: 'masterName',
      headerName: 'ФИО инженера по метрологии',
    },
    {
      field: 'planDate',
      headerName: 'Дата поверки',
    },
    {
      field: 'numNotification',
      headerName: '№ свидетельства / извещения',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: params => {
        return [
          <div
            key={params.id}
            style={{
              display: entryId ? 'none' : 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {params.row.autoGenerated ? (
              <Tooltip title='Редактирование доступно только через протокол'>
                <div>
                  <PencilIcon color='#BDC1C4' />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Редактировать запись'>
                <div style={{ cursor: 'pointer' }} onClick={() => handleEditClick(params.row.id)}>
                  <PencilIcon />
                </div>
              </Tooltip>
            )}

            {userRole !== 'metrologist' ? (
              <Tooltip title='Удалить запись'>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setOpen(true))
                    dispatch(showBA(true))
                    dispatch(setBA('Подтвердить'))
                    dispatch(setTitle('Вы действительно хотите удалить запись?'))
                    dispatch(setChildren('Отменить действие будет уже невозможно.'))
                    dispatch(setConfirmAction(() => handleDelete(params.row.id)))
                  }}
                >
                  <DeleteIcon />
                </div>
              </Tooltip>
            ) : userRole === 'metrologist' && isTrusted ? (
              <Tooltip title='Удалить запись'>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setOpen(true))
                    dispatch(showBA(true))
                    dispatch(setTitle('Вы действительно хотите удалить запись?'))
                    dispatch(setChildren('Отменить действие будет уже невозможно.'))
                    dispatch(setConfirmAction(() => handleDelete(params.row.id)))
                  }}
                >
                  <DeleteIcon />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Удаление системных записей доступно только пользователям со статусом "Опытный"'>
                <div>
                  <DeleteIcon color='#BDC1C4' />
                </div>
              </Tooltip>
            )}
          </div>,
        ]
      },
    },
  ].filter(row => {
    if (!!!entryId) {
      return row
    } else {
      if (row.field !== 'actions') {
        return row
      }
    }
  })

  const handleResetFilter = () => {
    dispatch(resetPersonal())
  }
  const changeSearchQuery = param => {
    dispatch(setSearchQuery(param))
  }
  const changeMeasurementId = param => {
    dispatch(changePersonal({ value: 'measurementId', label: param }))
  }
  const changeCompletenessStatus = event => {
    dispatch(changePersonal({ value: 'completenessStatus', label: event.target.value }))
  }
  const changeCreatedByRole = event => {
    dispatch(changePersonal({ value: 'createdByRole', label: event.target.value }))
  }
  const handleCreatedAtFromChange = date => {
    if (date) {
      dispatch(
        changePersonal({
          value: 'createdAtFrom',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changePersonal({ value: 'createdAtFrom', label: '' }))
    }
  }
  const handleCreatedAtToChange = date => {
    if (date) {
      dispatch(
        changePersonal({
          value: 'createdAtTo',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changePersonal({ value: 'createdAtTo', label: '' }))
    }
  }
  const handleVerificationDateFromChange = date => {
    if (date) {
      dispatch(
        changePersonal({
          value: 'verificationDateFrom',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changePersonal({ value: 'verificationDateFrom', label: '' }))
    }
  }
  const handleVerificationDateToChange = date => {
    if (date) {
      dispatch(
        changePersonal({
          value: 'verificationDateTo',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changePersonal({ value: 'verificationDateTo', label: '' }))
    }
  }

  const handleDelete = async journalId => {
    const { status } = await R.deletePersonalMetrolog(journalId, companyId, masterArshine)
    if (status === 200) {
      dispatch(refreshPersonalMetrolog())
      dispatch(setType('success'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Запись удалена'))
      dispatch(setOpen(false))
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Произошла ошибка обращения к API'))
      dispatch(resetState())
    }
  }

  const handleAddClick = () => {
    navigate('/metriva/journals/create_line_personal_master-poveritel')
  }

  const handleEditClick = id => {
    navigate(`/metriva/journals/${id}/edit_personal_master-poveritel`)
  }

  const handleDownloadSelectionPdf = async () => {
    const { status, data: responseData } = await R.getArchiveEntriesPdf(
      companyId,
      `?ids[]=${entryId}`,
    )
    const currentDate = new Date().toLocaleDateString('ru-RU').replace(/\./g, '-')

    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([responseData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `Personalnyi_zhurnal_inzhinera_po_metrologii_(arhiv)_${currentDate}.pdf`,
      )
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке документов.'))
    }
  }

  useEffect(() => {
    if (localArchiveDateFrom && archiveDateTo) {
      dispatch(showBA(true))
      dispatch(setConfirmAction(handleArchiveClick))
    } else {
      dispatch(showBA(false))
    }
  }, [localArchiveDateFrom, archiveDateTo])

  useEffect(() => {
    false && console.log(personalData)

    if (personalData.filter(row => row.arshin_master_id === masterArshine)[0]) {
      setLocalArchiveDateFrom(
        personalData.filter(row => row.arshin_master_id === masterArshine)[0].create_date,
      )
    } else {
      setLocalArchiveDateFrom('')
    }
  }, [personalData, masterArshine])

  useEffect(() => {
    return () => {
      handleResetFilter()
    }
  }, [])

  // ----------------------------------------------------------------
  // перенести всю логику получения данных в рефреш на ближайшем спринте !!!
  // ----------------------------------------------------------------

  useEffect(() => {
    if (companyId && masterArshine) {
      ;(async () => {
        try {
          const { data: conditionsItemData } = await R.getPersonalMetrolog(
            companyId,
            masterArshine,
            `?search=${searchQuery}&measurement_id=${measurementId}&completeness_status=${completenessStatus}&created_by_role=${createdByRole}&created_at_from=${createdAtFrom}&created_at_to=${createdAtTo}&verification_date_from=${verificationDateFrom}&verification_date_to=${verificationDateTo}&limit=10000`,
          )
          const data = conditionsItemData.data
          setData(data)
        } catch (error) {
          console.error(error)
        }
      })()
    }

    if (companyId && entryId) {
      ;(async () => {
        try {
          const { data: conditionsItemData } = await R.getArchiveEntriesJournals(
            companyId,
            entryId,
            `?search=${searchQuery}&measurement_id=${measurementId}&completeness_status=${completenessStatus}&created_by_role=${createdByRole}&created_at_from=${createdAtFrom}&created_at_to=${createdAtTo}&verification_date_from=${verificationDateFrom}&verification_date_to=${verificationDateTo}&limit=10000`,
          )
          const data = conditionsItemData.data
          setData(data)
        } catch (error) {
          console.error(error)
        }
      })()
    }
  }, [
    companyId,
    masterArshine,
    dispatch,
    searchQuery,
    measurementId,
    createdAtFrom,
    createdAtTo,
    verificationDateFrom,
    verificationDateTo,
    completenessStatus,
    createdByRole,
    metrologPing,
  ])

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleArchiveClick = async () => {
    const { status, data } = await R.archivePersonalMetrolog(companyId, masterArshine, {
      date_from: archiveDateFrom,
      date_to: archiveDateTo,
    })

    if (status === 200) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(false))
      dispatch(refreshPersonalMetrolog())
      dispatch(refreshArchives())
      dispatch(resetArchiveFrom())
      dispatch(resetArchiveTo())
      dispatch(resetState())
    } else if (status === 409) {
      dispatch(resetState())
      dispatch(setTitle(archiveTitle))
      dispatch(setChildren(archiveMessage))
      dispatch(setBD('Принять'))
      dispatch(showBA(false))
      dispatch(setOpen(true))
    } else if (status === 422) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(true))
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(true))
    }
  }

  const modalContent = (
    <ModalContent
      archiveDateFromBySettings={localArchiveDateFrom}
      archiveDateTo={archiveDateTo}
      setArchiveTo={setArchiveTo}
      dispatch={dispatch}
    />
  )

  const StaticControls = (
    <IconFrame>
      {count > 0 ? (
        <Tooltip title='Для скачивания PDF отключите фильтры'>
          <div style={{ cursor: 'default', opacity: '40%', display: 'flex', alignItems: 'center' }}>
            <Pdf />
          </div>
        </Tooltip>
      ) : (
        <Tooltip title='Скачать в формате PDF'>
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => {
              !entryId && handleDownload('pdf')
              !!entryId && handleDownloadSelectionPdf()
            }}
          >
            <Pdf />
          </div>
        </Tooltip>
      )}
      {!entryId && (
        <Tooltip title='Скачать документ в Csv'>
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => handleDownload('csv')}
          >
            <Csv />
          </div>
        </Tooltip>
      )}
      {!entryId && (
        <Tooltip title='Скачать документ в Excel'>
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => handleDownload('excel')}
          >
            <Excel />
          </div>
        </Tooltip>
      )}
      {(userRole === 'super' || userRole === 'admin') && !!!entryId && (
        <React.Fragment>
          {count > 0 ? (
            <Tooltip title='Для архивации документа отключите фильтры'>
              <div
                style={{ cursor: 'default', opacity: '40%', display: 'flex', alignItems: 'center' }}
              >
                <ArchiveIcon />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title='Архивировать документ'>
              <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                <ArchiveIcon
                  onClick={() => {
                    dispatch(setOpen(true))
                    dispatch(setTitle('Архивация документа'))
                    dispatch(setChildren(modalContent))
                    dispatch(setBA('Архивировать'))
                    dispatch(setBD('Отменить'))
                    dispatch(showBD(true))
                    dispatch(setConfirmAction(handleArchiveClick))
                  }}
                />
              </div>
            </Tooltip>
          )}
        </React.Fragment>
      )}
    </IconFrame>
  )

  return (
    <React.Fragment>
      <RightSideContainer blockTitle={'Фильтры'} containerWidth={400}>
        <Wrapper>
          <FrameFilter>
            <Typography variant='subtitle1'>Область измерения </Typography>
            <Measurement
              measurementId={measurementId}
              setMeasurementId={changeMeasurementId}
              isRequired={false}
            />
          </FrameFilter>

          {!entryId && (
            <FrameFilter>
              <Typography variant='subtitle1'>Заполнение записи</Typography>
              <RadioButton
                value={completenessStatus}
                onChange={changeCompletenessStatus}
                trueLabel={'Частичное'}
                falseLabel={'Полное'}
                trueValue={'non-complete'}
                falseValue={'complete'}
              />
            </FrameFilter>
          )}

          <FrameFilter>
            <Typography variant='subtitle1'>Создатель записи</Typography>
            <RadioButton
              value={createdByRole}
              onChange={changeCreatedByRole}
              trueLabel={'Система'}
              falseLabel={'Пользователь'}
              trueValue={'system'}
              falseValue={'user'}
            />
          </FrameFilter>

          <FrameFilter>
            <Typography variant='subtitle1'>Дата поверки</Typography>
            <DatePicker selectedDate={createdAtFrom} dateChange={handleCreatedAtFromChange} />

            <DatePicker selectedDate={createdAtTo} dateChange={handleCreatedAtToChange} />
          </FrameFilter>
          <FrameFilter>
            <Typography variant='subtitle1'>Дата создания записи</Typography>
            <DatePicker
              selectedDate={verificationDateFrom}
              dateChange={handleVerificationDateFromChange}
            />

            <DatePicker
              selectedDate={verificationDateTo}
              dateChange={handleVerificationDateToChange}
            />
          </FrameFilter>
          <Button
            onClick={handleResetFilter}
            label='Сбросить все значения'
            color='secondary'
            fullWidth
          />
        </Wrapper>
      </RightSideContainer>
      <Table
        colData={colData}
        rowData={data.map(item => ({
          id: item.id,
          number: item?.number ?? '-',
          numSi: item?.fif_number ?? '-',
          nameSi: item?.customer_arshin_name ?? '-',
          nameTypeSi: item?.name_type_si ?? '-',
          numFactory: item?.meter_factory_number ?? '-',
          masterName: item?.arshin_master_name ?? '-',
          planDate: item?.verification_date ?? '-',
          numNotification: item?.certificate ?? '-',
          autoGenerated: item?.is_autogenerated ?? '-',
        }))}
        hasCheckboxSelection={false}
        showToolbar={true}
        noCreating={!entryId ? false : true}
        actions={{
          static: StaticControls,
          dinamic: null,
        }}
        filtersVariant={'server'}
        addButtonCallback={handleAddClick}
        setIsDrawerOpenServer={() => {
          dispatch(setOpenRightContainer(true))
        }}
        noRowsText={'Записи отсутствуют'}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
    </React.Fragment>
  )
}

export default TablePersonal
