import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const CheckCircleIcon = props => (
  <SvgIcon
    {...props}
    width='21'
    height='25'
    viewBox='0 0 21 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17 11.9731C17 16.3914 13.4183 19.9731 9 19.9731C4.58172 19.9731 1 16.3914 1 11.9731C1 7.55487 4.58172 3.97314 9 3.97314C13.4183 3.97314 17 7.55487 17 11.9731ZM13.4194 9.21344C13.8567 9.64403 13.862 10.3475 13.4314 10.7847L8.81942 15.4676C8.38883 15.9049 7.68533 15.9102 7.24812 15.4796C7.2004 15.4326 7.15783 15.3824 7.12041 15.3296L4.72577 13.4119C4.24678 13.0283 4.16944 12.3291 4.55303 11.8501C4.93661 11.3711 5.63587 11.2938 6.11485 11.6773L7.97011 13.1631L11.8481 9.22543C12.2787 8.78821 12.9822 8.78285 13.4194 9.21344Z'
      fill='#21E1B3'
    />
  </SvgIcon>
)

export default CheckCircleIcon
