import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    light?: string
    error?: string
  }

  interface TypeText {
    dark?: string
  }
}

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: '12px 12px 0px 12px',
          backgroundColor: '#132532',
          boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.20)',
          margin: '0px 30px !important',
          padding: '10px 16px',
          lineHeight: '20px',
          fontWeight: 400,
          fontSize: '13px',
        },
      },
    },
  },
  typography: {
    fontFamily: '"Wix Madefor Display", sans-serif',

    h1: {
      fontFamily: '"Wix Madefor Display", sans-serif',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: '110%',
      color: '#132532',
    },
    h2: {
      fontFamily: '"Wix Madefor Display", sans-serif',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: '110%',
      color: '#132532',
    },
    h3: {
      fontFamily: '"Wix Madefor Display", sans-serif',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '110%',
      color: '#132532',
    },
    h4: {
      fontFamily: '"Wix Madefor Display", sans-serif',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '110%',
      color: '#132532',
    },
    h5: {
      fontFamily: '"Wix Madefor Display", sans-serif',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '110%',
      color: '#132532',
    },
    h6: {
      fontFamily: '"Wix Madefor Display", sans-serif',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '600 !important',
      lineHeight: '110%',
    },
    subtitle1: {
      fontFamily: '"Wix Madefor Display", sans-serif',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '140%',
    },
    subtitle2: {
      fontFamily: '"Wix Madefor Display", sans-serif',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 400,
      color: '#4f5960',
    },
    caption: {
      fontFamily: '"Wix Madefor Display", sans-serif',
      fontSize: '15x',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '140%',
      color: '#4f5960',
    },
    body1: {
      fontFamily: '"Wix Madefor Display", sans-serif',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '140%',
      color: '#4f5960',
    },
    body2: {
      fontFamily: '"Wix Madefor Display", sans-serif',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: '140%',
      color: '#132532',
    },
  },
  palette: {
    background: {
      default: '#FFFFFF',
      light: '#BDC1C4',
      error: '#FF5963',
    },
    text: {
      primary: '#132532',
      secondary: '#0084E2',
      dark: '#4f5960',
    },
    primary: {
      main: '#0084E2',
      light: '#0093FD',
    },
    secondary: {
      main: '#d7ddea',
      light: '#8FCE10',
      dark: '#FF6E77',
      contrastText: '#00A37C',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 641,
      md: 721,
      lg: 961,
      xl: 1281,
    },
  },
})

export default theme
