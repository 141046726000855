// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import Tooltip from '../../../ui/tooltip'
import Button from '../../../ui/button'
import FilterIcon from '../../../img/icon/FilterIcon'
import PencilIcon from '../../../img/icon/PencilIcon'
import DeleteIcon from '../../../img/icon/DeleteIcon'
import { Typography, Fade, Collapse } from '@mui/material'

import R from '../../../services/app/request.service'
import Table from '../../components/LocalElements/Table/Table'
import FilterCompany from '../../components/LocalElements/Filter/FilterCompany'
import SearchQuery from '../../components/LocalElements/Search/SearchQuery'
import Skeleton from '../../components/LocalElements/Skeleton/Skeleton'
import BasicTabs from '../../components/LocalElements/TabsMenu/TabsMenu'

import useWindowDimensions from '../../hooks/useWindowDimensions'

import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  resetState,
} from '../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'
import {
  setDataEtalon,
  setSearchQuery as setSearchQueryEtalon,
  setSelectedEtalons,
} from '../../../store/slices/app/views/etalon'
import {
  refreshEtalons,
  refreshKitPassport,
  refreshKitVerifications,
} from '../../../store/slices/app/controlers/updater'
import { setName } from '../../../store/slices/app/controlers/pageName'

import { IModelEtalonsList } from '../../types'

const IndexEtalons = () => {
  const userRole = useSelector((state: any) => state.headerReducer.role)

  const { width } = useWindowDimensions()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isToggle = useSelector((state: any) => state.toggleReducer.isToggle)
  const isLoading = useSelector((state: any) => state.etalonReducer.isLoading)
  const dataEtalons = useSelector((state: any) => state.etalonReducer.dataEtalon)
  const selectedEtalons = useSelector((state: any) => state.etalonReducer.selectedEtalons)

  console.log(dataEtalons)

  const etalonSearchQuery = useSelector((state: any) => state.etalonReducer.searchQuery)
  const companyIdSuperAdmin = useSelector((state: any) => state.companyIdReducer.companyId)
  const companyIdUser = useSelector((state: any) => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const changeSearchQuery = (param: any) => {
    dispatch(setSearchQueryEtalon(param))
  }

  const handleCreate = () => {
    navigate('/metriva/etalon/create')
  }

  const handleEdit = (param: { id: any }) => {
    navigate(`/metriva/etalon/${param.id}/edit`)
  }

  const handleDelete = async (etalonId: any) => {
    const { status } = await R.deleteEtalon(etalonId)
    if (status === 200) {
      dispatch(refreshEtalons(''))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Средство поверки было удалено'))
      dispatch(setOpen(false))
      dispatch(resetState())

      setTimeout(() => {
        dispatch(refreshKitPassport(''))
        dispatch(refreshKitVerifications(''))
      }, 4000)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Произошла ошибка обращения к API'))
    }
  }

  let colData = [
    {
      field: 'title',
      headerName: 'Наименование и тип',
      type: 'string',
      disableFilter: true,
    },
    {
      field: 'manufacturerNumber',
      headerName: 'Заводской номер',
      type: 'string',
      disableFilter: true,
    },
    {
      field: 'inventoryNumber',
      headerName: 'Инвентарный номер',
      type: 'string',
      disableFilter: true,
    },
    {
      field: 'status',
      headerName: 'Текущий статус',
      type: 'string',
      renderCell: (params: {
        row: {
          status:
            | 'Хранение (консервация)'
            | 'in_storage'
            | 'В эксплуатации'
            | 'in_use'
            | 'Поверка'
            | 'verification'
            | 'Аттестация'
            | 'attestation'
            | 'Калибровка'
            | 'calibration'
            | 'Ремонт'
            | 'repair'
            | 'Списано'
            | 'decommissioned'
        }
      }) => {
        const { status } = params.row

        if (status === 'in_use' || status === 'В эксплуатации') {
          return <p style={{ color: '#00A37C', fontSize: '0.875rem' }}>В эксплуатации</p>
        } else if (status === 'in_storage' || status === 'Хранение (консервация)') {
          return <p style={{ color: 'gray', fontSize: '0.875rem' }}>Хранение (консервация)</p>
        } else if (status === 'verification' || status === 'Поверка') {
          return <p style={{ color: '#E57004', fontSize: '0.875rem' }}>Поверка</p>
        } else if (status === 'attestation' || status === 'Аттестация') {
          return <p style={{ color: '#E57004', fontSize: '0.875rem' }}>Аттестация</p>
        } else if (status === 'calibration' || status === 'Калибровка') {
          return <p style={{ color: '#E57004', fontSize: '0.875rem' }}>Калибровка</p>
        } else if (status === 'repair' || status === 'Ремонт') {
          return <p style={{ color: '#E57004', fontSize: '0.875rem' }}>Ремонт</p>
        } else if (status === 'decommissioned' || status === 'Списано') {
          return <p style={{ color: '#E74953', fontSize: '0.875rem' }}>Списано</p>
        }
      },
    },
    {
      field: 'group',
      headerName: 'Группа СП',
      type: 'string',
    },
    {
      field: 'isSyncAvailable',
      headerName: 'Отправка данных о СП в Аршин',
      type: 'boolean',
    },
    {
      field: 'listedInSets',
      headerName: 'СП числится в комплектах',
      type: 'arrayString',
    },
    {
      field: 'lastCheckDate',
      headerName: 'Дата последней поверки/аттестации',
      type: 'string',
      disableFilter: true,
    },
    {
      field: 'endCheckDate',
      headerName: 'Дата истечения срокаповерки/аттестации',
      type: 'string',
      disableFilter: true,
    },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: (item: any) => {
        return [
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Tooltip title='Редактировать средство поверки' disableHoverListener={undefined}>
              <div style={{ cursor: 'pointer' }} onClick={() => handleEdit(item)}>
                <PencilIcon onClick={undefined} />
              </div>
            </Tooltip>
            {item.row.listedInSets === false && (
              <Tooltip title='Удалить средство поверки' disableHoverListener={undefined}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setOpen(true))
                    dispatch(setTitle('Вы действительно хотите удалить СП?'))
                    dispatch(setChildren('Отменить действие будет уже невозможно.'))
                    dispatch(setConfirmAction(() => handleDelete(item.id)))
                  }}
                >
                  <DeleteIcon onClick={() => {}} />
                </div>
              </Tooltip>
            )}
            {item.row.listedInSets === true && (
              <Tooltip
                title='Удаление недоступно, средство поверки является частью комплекта СП'
                disableHoverListener={undefined}
              >
                <div style={{ cursor: 'pointer', opacity: 0.8 }} onClick={() => {}}>
                  <DeleteIcon onClick={() => {}} />
                </div>
              </Tooltip>
            )}
          </div>,
        ]
      },
    },
  ]

  useEffect(() => {
    dispatch(setName('База эталонов'))
  }, [])

  const DinamicActions = (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        height: '100%',
        gap: '10px',
      }}
    >
      <Button
        color='text'
        startIcon={
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M2.75 6.16667C2.75 5.70644 3.09538 5.33335 3.52143 5.33335L6.18567 5.3329C6.71502 5.31841 7.18202 4.95482 7.36214 4.41691C7.36688 4.40277 7.37232 4.38532 7.39185 4.32203L7.50665 3.94993C7.5769 3.72179 7.6381 3.52303 7.72375 3.34536C8.06209 2.64349 8.68808 2.1561 9.41147 2.03132C9.59457 1.99973 9.78848 1.99987 10.0111 2.00002H13.4891C13.7117 1.99987 13.9056 1.99973 14.0887 2.03132C14.8121 2.1561 15.4381 2.64349 15.7764 3.34536C15.8621 3.52303 15.9233 3.72179 15.9935 3.94993L16.1083 4.32203C16.1279 4.38532 16.1333 4.40277 16.138 4.41691C16.3182 4.95482 16.8778 5.31886 17.4071 5.33335H19.9786C20.4046 5.33335 20.75 5.70644 20.75 6.16667C20.75 6.62691 20.4046 7 19.9786 7H3.52143C3.09538 7 2.75 6.62691 2.75 6.16667Z'
              fill='#4B5962'
            />
            <path
              d='M11.6068 21.9998H12.3937C15.1012 21.9998 16.4549 21.9998 17.3351 21.1366C18.2153 20.2734 18.3054 18.8575 18.4855 16.0256L18.745 11.945C18.8427 10.4085 18.8916 9.6402 18.45 9.15335C18.0084 8.6665 17.2628 8.6665 15.7714 8.6665H8.22905C6.73771 8.6665 5.99204 8.6665 5.55047 9.15335C5.10891 9.6402 5.15777 10.4085 5.25549 11.945L5.515 16.0256C5.6951 18.8575 5.78515 20.2734 6.66534 21.1366C7.54553 21.9998 8.89927 21.9998 11.6068 21.9998Z'
              fill='#4B5962'
            />
          </svg>
        }
        endIcon={undefined}
        label='Скачать регистрационную карточку (паспорт) выбранных СП'
        onClick={() => {}}
        styles={{
          padding: 0,
          color: 'inherit',
          minWidth: '50px',
          paddingLeft: '16px',
          paddingRight: '14px',
          marginLeft: '-10px',
          fontSize: '.88rem',
        }}
      />
    </div>
  )

  return (
    <main className={isToggle ? 'main_open' : 'main'}>
      {userRole === 'super' && (
        <div
          style={{
            width: width > 1050 ? '605px' : '100%',
            marginBottom: '0px',
          }}
        >
          <FilterCompany />
        </div>
      )}

      <Collapse in={!companyId} timeout={500} unmountOnExit>
        <div className='page_null'>
          <Fade in={!companyId} timeout={500}>
            <Typography variant='body1'>Для просмотра данных выберите компанию</Typography>
          </Fade>
        </div>
      </Collapse>

      <Collapse in={!!companyId} timeout={500} unmountOnExit>
        <Fade in={!!companyId} timeout={500}>
          <div>
            {/* <div style={{ margin: '10px 0 12px' }}>
              <BasicTabs
                viewVariant={0}
                callback={() => {}}
                tabs={[{ label: 'Список средств поверки' }]}
                containnerCSS={undefined}
              />
            </div> */}

            {isLoading ? (
              <Skeleton />
            ) : (
              <Table
                colData={colData}
                rowData={dataEtalons.map((item: IModelEtalonsList) => ({
                  id: item.id,
                  title: item.name_type,
                  manufacturerNumber: item.manufacturer_number ?? '-',
                  inventoryNumber: item.inventory_number ?? '-',
                  isSyncAvailable: item.is_sync_available,
                  group: item.kit_group_name,
                  status:
                    item.status === 'in_use'
                      ? 'В эксплуатации'
                      : item.status === 'in_storage'
                        ? 'Хранение (консервация)'
                        : item.status === 'verification'
                          ? 'Поверка'
                          : item.status === 'attestation'
                            ? 'Аттестация'
                            : item.status === 'calibration'
                              ? 'Калибровка'
                              : item.status === 'repair'
                                ? 'Ремонт'
                                : item.status === 'decommissioned'
                                  ? 'Списано'
                                  : 'Неизвестный статус',
                  lastCheckDate: moment(item.last_date).format('DD-MM-YYYY'),
                  endCheckDate: moment(item.expired_date).format('DD-MM-YYYY'),
                  listedInSets:
                    item.cases && item.cases.length > 0
                      ? item.cases.map(itemCase => itemCase.inner_name).join(' | ')
                      : '-',
                }))}
                rowCallback={handleEdit}
                addButtonCallback={handleCreate}
                searchParams={{ query: etalonSearchQuery, builder: changeSearchQuery }}
                showToolbar={true}
                actions={{
                  static: null,
                  dinamic: selectedEtalons && selectedEtalons.length > 0 ? DinamicActions : null,
                }}
                hasCheckboxSelection={true}
                rowSelectionCallback={array => {
                  dispatch(setSelectedEtalons(array))
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
              />
            )}
          </div>
        </Fade>
      </Collapse>
    </main>
  )
}

export default IndexEtalons
