import styled from 'styled-components'

interface IContentRowProps {
  readonly axios?: 'start' | 'end'
  readonly gap?: number
  readonly mb?: number
}

const css = {
  Container: styled.section`
    display: flex;
    flex-flow: column;
    position: relative;
    border-radius: 12px;
    border: 1px solid #d7ddea;
    min-height: 100px;
    width: 100%;
    padding: 22px;
    margin-top: 12px;
    gap: 12px;
  `,
  ContentRow: styled.div<IContentRowProps>`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: ${props => (props.axios ? 'flex-' + props.axios : 'flex-start')};
    gap: ${props => (props.gap ? props.gap + 'px' : '0px')};
    margin-bottom: ${props => (props.mb ? props.mb + 'px' : '0px')};
    position: relative;
    width: 100%;
    line-height: 22px;
  `,
}

export default css
