const CopyIcon = ({ color = '#899298', width = 24, height = 24, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 20 21'
      onClick={onClick}
    >
      <path
        d='M12.7 2.23169H9.45487C7.98466 2.23168 6.82015 2.23167 5.90878 2.35469C4.97084 2.4813 4.21169 2.74806 3.613 3.34915C3.0143 3.95025 2.74861 4.71246 2.62251 5.65416C2.49998 6.56919 2.49999 7.73837 2.5 9.21448V14.0791C2.5 15.3358 3.26663 16.4128 4.35597 16.8644C4.2999 16.1065 4.29995 15.0431 4.3 14.1584L4.3 10.063L4.3 9.9837C4.29994 8.91564 4.29989 7.99539 4.39857 7.25845C4.50433 6.46867 4.74282 5.71162 5.35442 5.09757C5.96601 4.48352 6.72003 4.24407 7.50665 4.13788C8.24064 4.0388 9.15722 4.03886 10.221 4.03892L10.3 4.03892H12.7L12.779 4.03892C13.8428 4.03886 14.7574 4.0388 15.4914 4.13788C15.0523 3.02151 13.968 2.23169 12.7 2.23169Z'
        fill={color}
      />
      <path
        d='M5.5 10.0627C5.5 7.79086 5.5 6.65494 6.20294 5.94918C6.90589 5.24341 8.03726 5.24341 10.3 5.24341H12.7C14.9627 5.24341 16.0941 5.24341 16.7971 5.94918C17.5 6.65494 17.5 7.79086 17.5 10.0627V14.0787C17.5 16.3506 17.5 17.4865 16.7971 18.1923C16.0941 18.898 14.9627 18.898 12.7 18.898H10.3C8.03726 18.898 6.90589 18.898 6.20294 18.1923C5.5 17.4865 5.5 16.3506 5.5 14.0787V10.0627Z'
        fill={color}
      />
    </svg>
  )
}

export default CopyIcon
