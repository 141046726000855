import React from 'react'
import AccordionComponent from '../../../LocalElements/Accordion/Accordion'

const Cases = ({ data }) => {
  const caseData = [
    { title: 'Название и тип устройства' },
    { title: 'Заводской номер' },
    { title: 'Регистрационный номер' },
    { title: 'Описание и ссылка' },
  ]
  return (
    <React.Fragment>
      <AccordionComponent
        title={`Комплект СП - ${data?.archivedCase?.inner_name}`}
        dataType='custom'
        backgroundColor='white'
        border={'1px solid #d7ddea'}
        data={
          <div>
            <div
              style={{
                display: 'flex',
                gap: '16px',
                background: '#EAEDF3',
                borderRadius: '4px 4px 0 0',
                padding: '0px 14px',
              }}
            >
              {caseData.map((item, index) => (
                <div
                  key={index}
                  style={{
                    width: index === 0 ? '30%' : index === 1 || index === 2 ? '15%' : '40%',
                    height: '60px',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    fontWeight: '600',
                  }}
                >
                  <span>
                    {item.title === 'Регистрационный номер' ? 'Регист. номер' : item.title}
                  </span>
                </div>
              ))}
            </div>

            {data?.archivedCase?.archivedKits?.map((item, index) => (
              <div
                key={item.id}
                style={{
                  display: 'flex',
                  background: index === 1 || index === 3 ? '#EAEDF3' : 'transparent',
                  gap: '16px',
                  padding: '10px 14px 12px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '30%',
                    justifyContent: 'center',
                    gap: '5px',
                  }}
                >
                  <span>{item.device?.name}</span>
                  <span>{item?.device_sign}</span>
                </div>
                <span
                  style={{
                    width: '15%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {item?.manufacturer_number}
                </span>
                <span
                  style={{
                    width: '15%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {item?.register_number}
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '40%',
                    justifyContent: 'center',
                    gap: '5px',
                  }}
                >
                  <span>{item?.type}</span>
                  <a
                    href={item?.kit_verification_link}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ color: '#0084E2', textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    {item?.kit_verification_link}
                  </a>
                </div>
              </div>
            ))}
          </div>
        }
      />
    </React.Fragment>
  )
}

export default Cases
