import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const ExitIcon = props => {
  return (
    <SvgIcon
      {...props}
      width='21'
      height='25'
      viewBox='0 0 21 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.21484 15.75C12.4951 15.75 15.9648 12.2802 15.9648 8C15.9648 3.71979 12.4951 0.25 8.21484 0.25C3.93464 0.25 0.464844 3.71979 0.464844 8C0.464844 12.2802 3.93464 15.75 8.21484 15.75ZM6.22701 4.58752C5.83365 4.19416 5.19589 4.19416 4.80253 4.58752C4.40917 4.98088 4.40917 5.61864 4.80253 6.012L6.81405 8.02353L4.85012 9.98746C4.45676 10.3808 4.45676 11.0186 4.85012 11.4119C5.24348 11.8053 5.88124 11.8053 6.2746 11.4119L8.23853 9.44801L10.1551 11.3646C10.5484 11.7579 11.1862 11.7579 11.5796 11.3646C11.9729 10.9712 11.9729 10.3334 11.5796 9.94008L9.66301 8.02353L11.6272 6.05938C12.0205 5.66602 12.0205 5.02826 11.6272 4.6349C11.2338 4.24154 10.596 4.24154 10.2027 4.6349L8.23853 6.59905L6.22701 4.58752Z'
        fill={'#FF5963'}
      />
    </SvgIcon>
  )
}

export default ExitIcon
