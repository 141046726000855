// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import * as React from 'react'
import ReactDOM from 'react-dom'

import { useSelector, useDispatch } from 'react-redux'
import { setOpen } from '../../../../store/slices/app/comps/rightContainer'

import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CloseIcon from '@mui/icons-material/Close'

import useWindowDimensions from '../../../hooks/useWindowDimensions'

const RightSideContainer = ({ children, blockTitle, containerWidth = 400, columns = 1 }) => {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const open = useSelector(state => state.rightContainerReducer.open)

  const toggleDrawer = open => event => {
    if (event) {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }
    }

    dispatch(setOpen(open))
  }

  const list = childrenElement => (
    <Box
      sx={{ width: width > 1050 ? containerWidth : '100%', padding: '20px' }}
      role='presentation'
    >
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h4
          style={{
            display: 'block',
            marginBottom: '20px',
            fontSize: '18.8px',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {blockTitle}
        </h4>
        <span
          onClick={() => dispatch(setOpen(false))}
          style={{
            cursor: 'pointer',
            marginTop: '-12px',
          }}
        >
          <CloseIcon />
        </span>
      </div>
      <section
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          width: '100%',
          position: 'relative',
        }}
      >
        {childrenElement}
      </section>
    </Box>
  )

  return ReactDOM.createPortal(
    <div>
      <React.Fragment>
        <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)}>
          {list(children)}
        </Drawer>
      </React.Fragment>
    </div>,
    document.body,
  )
}

export default RightSideContainer
