import { createSlice } from '@reduxjs/toolkit'
import { format } from 'date-fns'

const initialState = {
  companiesList: null,
  selectedCompany: null,
  selectedCompanies: [],
  selectedCompanyRow: [],
  viewVariant: 0,
  viewVariantMain: 0,
  meta: null | undefined,
  isLoading: true,
  searchQuery: '',
  lanBillingSelector: 'Не выбрано',
  statusSelector: '',
  tariffSelector: '',
  tariffs: [],
  companyId: null,
  createData: {
    main: {
      name: '',
      ceo: '',
      number: '',
      email: '',
      regionId: '',
      address: '',
      logo: null,
      status: '',
    },
    over: {
      rs: '',
      ks: '',
      bank: '',
      inn: '',
      kpp: '',
      ogrn: '',
      bik: '',
      bankAddress: '',
      code: '',
      num: '',
      orderNum: '',
      orderDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    },
  },

  tariff: {
    startDate: format(new Date(), 'yyyy-MM-dd'),
    finishDate: '',
    frozenDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    frozenValue: '',
  },
}

const companiesReducer = createSlice({
  name: 'companiesReducer',
  initialState,
  reducers: {
    setList: (state, action) => {
      state.companiesList = action.payload
    },
    pushList: (state, action) => {
      state.companiesList.push(action.payload)
    },
    clearList: (state, action) => {
      state.companiesList = []
    },
    setMeta: (state, action) => {
      state.meta = action.payload
    },
    setLanBilling: (state, action) => {
      state.lanBillingSelector = action.payload
    },
    setStatus: (state, action) => {
      state.statusSelector = action.payload
    },
    setTariff: (state, action) => {
      state.tariffSelector = action.payload
    },
    clearMeta: (state, action) => {
      state.meta = null
    },
    clearCreateData: (state, action) => {
      state.createData = initialState.createData
    },
    changeMainData: (state, action) => {
      state.createData.main[action.payload.value] = action.payload.label
    },
    changeOverData: (state, action) => {
      state.createData.over[action.payload.value] = action.payload.label
    },
    changeTariffData: (state, action) => {
      state.tariff[action.payload.value] = action.payload.label
    },
    resetTariffData: state => {
      state.tariff = initialState.tariff
    },
    resetCreateData: state => {
      state.createData = initialState.createData
    },
    setViewVariant: (state, action) => {
      state.viewVariant = action.payload
    },
    setViewVariantMain: (state, action) => {
      state.viewVariantMain = action.payload
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setCompanyId: (state, action) => {
      state.companyId = action.payload
    },
    setSelectedCompanyRow: (state, action) => {
      state.selectedCompanyRow = action.payload
    },
    setSelectedCompanies: (state, action) => {
      state.selectedCompanies = action.payload
    },
    setTariffs: (state, action) => {
      state.tariffs = action.payload
    },
  },
})

export const {
  setList,
  pushList,
  clearList,
  setMeta,
  clearMeta,
  clearCreateData,
  changeMainData,
  changeOverData,
  changeTariffData,
  resetCreateData,
  resetTariffData,
  setViewVariant,
  setViewVariantMain,
  setSearchQuery,
  setIsLoading,
  setSelectedCompanyRow,
  setSelectedCompanies,
  setLanBilling,
  setStatus,
  setTariff,
  setCompanyId,
  setTariffs,
} = companiesReducer.actions
export default companiesReducer.reducer
