// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-unused-vars */
// ----------------------------------------------------------------
import React, { ReactElement } from 'react'

interface IStatusProp {
  status:
    | 'in_storage'
    | 'in_use'
    | 'verification'
    | 'attestation'
    | 'calibration'
    | 'repair'
    | 'decommissioned'
    | null
}

const StatusContainer: React.FC<IStatusProp> = ({ status }): ReactElement => {
  const styles = (
    status:
      | 'in_storage'
      | 'in_use'
      | 'verification'
      | 'attestation'
      | 'calibration'
      | 'repair'
      | 'decommissioned',
  ) => {
    if (status === 'in_storage') {
      return {
        backgroundColor: '#F5F7F9',
        borderColor: '#9F9F9F',
        color: '#9F9F9F',
      }
    }
    if (status === 'in_use') {
      return {
        backgroundColor: '#ECF3F8',
        borderColor: '#0084E2',
        color: '#0084E2',
      }
    } else {
      return {
        backgroundColor: '#FFF1E3',
        borderColor: '#FF9432',
        color: '#FF9432',
      }
    }
  }

  return (
    <React.Fragment>
      <div
        className='box'
        style={{
          ...styles(status ?? 'in_use'),
          transition: 'all 500ms',
        }}
      >
        {status === 'in_storage' && (
          <h2 style={{ marginBottom: '20px', color: 'inherit' }}>На хранении</h2>
        )}
        {status === 'in_use' && (
          <h2 style={{ marginBottom: '20px', color: 'inherit' }}>В эксплуатации</h2>
        )}
        {status === 'verification' && (
          <h2 style={{ marginBottom: '20px', color: 'inherit' }}>Поверка</h2>
        )}
        {status === 'attestation' && (
          <h2 style={{ marginBottom: '20px', color: 'inherit' }}>Аттестация</h2>
        )}
        {status === 'calibration' && (
          <h2 style={{ marginBottom: '20px', color: 'inherit' }}>Калибровка</h2>
        )}
        {status === 'decommissioned' && (
          <h2 style={{ marginBottom: '20px', color: 'inherit' }}>Техобслуживание</h2>
        )}

        {status === 'repair' && <h2 style={{ marginBottom: '20px', color: 'inherit' }}>Ремонт</h2>}

        {status === 'in_storage' && (
          <span style={{ color: 'inherit' }}>
            Средство поверки ожидает ввода в эксплуатацию или находится на длительном хранении (в
            консервации)
          </span>
        )}
        {status === 'in_use' && (
          <span style={{ color: 'inherit' }}>
            Средство поверки используется для проведения поверочных работ
          </span>
        )}
        {status === 'verification' && (
          <span style={{ color: 'inherit' }}>Средство поверки проходит поверку</span>
        )}
        {status === 'attestation' && (
          <span style={{ color: 'inherit' }}>Средство поверки проходит аттестацию</span>
        )}
        {status === 'calibration' && (
          <span style={{ color: 'inherit' }}>Средство поверки проходит калибровку</span>
        )}
        {status === 'decommissioned' && (
          <span style={{ color: 'inherit' }}>Средство поверки находится на техобслуживании</span>
        )}

        {status === 'repair' && (
          <span style={{ color: 'inherit' }}>
            Средство поверки проходит ремонт, модификацию или регулировку
          </span>
        )}
      </div>
    </React.Fragment>
  )
}

export default StatusContainer
