const ExportIcon = ({ color = '#4B5962', width = 25, height = 23, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 25 23'
      onClick={onClick}
    >
      <g clipPath='url(#clip0_1740_7340)'>
        <path
          d='M0 3.23139C0 1.69927 1.24566 0.453613 2.77778 0.453613H9.72222V6.00917C9.72222 6.7774 10.3429 7.39806 11.1111 7.39806H16.6667V12.9536H9.375C8.79774 12.9536 8.33333 13.418 8.33333 13.9953C8.33333 14.5725 8.79774 15.0369 9.375 15.0369H16.6667V19.8981C16.6667 21.4302 15.421 22.6758 13.8889 22.6758H2.77778C1.24566 22.6758 0 21.4302 0 19.8981V3.23139ZM16.6667 15.0369V12.9536H21.4453L19.7526 11.2609C19.3446 10.8529 19.3446 10.1932 19.7526 9.78955C20.1606 9.38591 20.8203 9.38156 21.224 9.78955L24.6962 13.2618C25.1042 13.6698 25.1042 14.3295 24.6962 14.7331L21.224 18.2053C20.816 18.6133 20.1563 18.6133 19.7526 18.2053C19.349 17.7974 19.3446 17.1376 19.7526 16.734L21.4453 15.0413L16.6667 15.0369ZM16.6667 6.00917H11.1111V0.453613L16.6667 6.00917Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_1740_7340'>
          <rect width='25' height='22.2222' fill='white' transform='translate(0 0.453613)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ExportIcon
