import { styled } from '@mui/material'

export const Wrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '14px',
}))

export const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: '14px',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}))

export const Frame = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',
  ':hover': {
    opacity: 0.8,
  },
}))

export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.secondary.main}`,
  gap: '20px',
  padding: '22px',
  borderRadius: '12px',
}))

export const ContentFrame = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '16px',
}))

export const LabelFrame = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',

  '& > .MuiTypography-root': {
    marginTop: '-4px',
  },
}))

export const ModalFrame = styled('div')(() => ({
  width: '620px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}))

export const ModalBox = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'space-between',
  marginTop: '10px',
  gap: '16px',
}))

export const Filter = styled('div')(() => ({
  position: 'relative',
}))

export const IconFrame = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
}))

export const CountLabel = styled('span')(() => ({
  display: 'block',
  position: 'absolute',
  width: '22px',
  height: '22px',
  borderRadius: '50%',
  backgroundColor: '#1976d2',
  color: 'white',
  fontSize: '11px',
  lineHeight: '21px',
  textAlign: 'center',
  top: 0,
  left: '100%',
  marginLeft: '-18px',
  marginTop: '-4px',
  transition: 'all 300ms',
}))

export const FrameFilter = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'column',
  margin: '0 !important',
  gap: '10px',

  padding: '14px',
  borderRadius: '12px',
  boxShadow: '0 1px 2px rgba(62, 62, 62, 0.28), 0 1px 2px rgba(62, 62, 62, 0.28)',
  transition: 'transform 0.35s, box-shadow 0.35s',
}))

export const FrameRadioBtn = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))
