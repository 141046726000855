const ArchiveIcon = ({ color = '#4B5962', width = 27, height = 27, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 24 25'
      onClick={onClick}
    >
      <path
        d='M1.32544 2.82544C1 3.15087 1 3.67466 1 4.72222C1 5.76978 1 6.29357 1.32544 6.619C1.65087 6.94444 2.17466 6.94444 3.22222 6.94444H21C22.0475 6.94444 22.5713 6.94444 22.8968 6.619C23.2222 6.29357 23.2222 5.76978 23.2222 4.72222C23.2222 3.67466 23.2222 3.15087 22.8968 2.82544C22.5713 2.5 22.0475 2.5 21 2.5H3.22222C2.17466 2.5 1.65087 2.5 1.32544 2.82544Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.0767 8.61117H3.14571C2.9889 8.61123 2.82761 8.61129 2.66666 8.60907V13.6111C2.66666 17.8014 2.66666 19.8965 3.96841 21.1983C5.27016 22.5 7.36529 22.5 11.5555 22.5H12.6667C16.8569 22.5 18.952 22.5 20.2538 21.1983C21.5555 19.8965 21.5555 17.8014 21.5555 13.6111V8.60907C21.3946 8.61129 21.2334 8.61123 21.0767 8.61117ZM8.86235 11.5192C8.77777 11.7234 8.77777 11.9823 8.77777 12.5C8.77777 13.0177 8.77777 13.2766 8.86235 13.4808C8.97512 13.753 9.19142 13.9693 9.46368 14.0821C9.66787 14.1667 9.92672 14.1667 10.4444 14.1667H13.7778C14.2955 14.1667 14.5543 14.1667 14.7585 14.0821C15.0308 13.9693 15.2471 13.753 15.3599 13.4808C15.4444 13.2766 15.4444 13.0177 15.4444 12.5C15.4444 11.9823 15.4444 11.7234 15.3599 11.5192C15.2471 11.247 15.0308 11.0307 14.7585 10.9179C14.5543 10.8333 14.2955 10.8333 13.7778 10.8333H10.4444C9.92672 10.8333 9.66787 10.8333 9.46368 10.9179C9.19142 11.0307 8.97512 11.247 8.86235 11.5192Z'
        fill={color}
      />
    </svg>
  )
}

export default ArchiveIcon
