import React, { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'

import ProtocolsIndex from '../app/pages/Protocols/ProtocolsIndex'
import EditProtocol from '../app/pages/Protocols/EditProtocol'
import CopyProtocol from '../app/pages/Protocols/CopyProtocol'
import Show from '../app/pages/Protocols/Show'

import NotForMobile from '../app/pages/NotForMobile'
import useWindowDimensions from '../app/hooks/useWindowDimensions'

const RouteProtocols: React.FC = (): ReactElement => {
  const { width } = useWindowDimensions()

  return (
    <Routes>
      <Route path='' element={width > 1050 ? <ProtocolsIndex /> : <NotForMobile />} />
      <Route path=':protocol_id' element={width > 1050 ? <Show /> : <NotForMobile />} />
      <Route
        path=':protocol_id/copy'
        element={width > 1050 ? <CopyProtocol /> : <NotForMobile />}
      />
      <Route
        path=':protocol_id/edit'
        element={width > 1050 ? <EditProtocol /> : <NotForMobile />}
      />
    </Routes>
  )
}

export default RouteProtocols
