import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isDrawerOpen: false,
}

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setIsDrawerOpen: (state, action) => {
      state.isDrawerOpen = action.payload
    },
  },
})

export const { setIsDrawerOpen } = drawerSlice.actions
export default drawerSlice.reducer
