const PencilDisabled = ({ color = '#899298', width = 20, height = 20, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 20 20'
      onClick={onClick}
    >
      <path
        d='M9.4994 15.1342L9.49943 15.1342L15.6626 8.97093C14.8238 8.62181 13.8303 8.04834 12.8907 7.10874C11.951 6.16899 11.3775 5.17532 11.0284 4.33642L4.86504 10.4998L4.86502 10.4998C4.38407 10.9807 4.14358 11.2212 3.93676 11.4864C3.69279 11.7992 3.48363 12.1376 3.31297 12.4957C3.16829 12.7993 3.06075 13.1219 2.84565 13.7672L1.71139 17.17C1.60554 17.4875 1.68819 17.8376 1.92487 18.0743C2.16156 18.311 2.51167 18.3936 2.82922 18.2878L6.232 17.1535C6.87728 16.9384 7.19993 16.8309 7.5035 16.6862C7.86159 16.5156 8.20003 16.3064 8.51282 16.0624C8.77797 15.8556 9.01847 15.6151 9.4994 15.1342Z'
        fill='#BDC1C4'
      />
      <path
        d='M17.3729 7.26072C18.6526 5.98096 18.6526 3.90607 17.3729 2.62632C16.0931 1.34657 14.0182 1.34657 12.7385 2.62632L11.9993 3.36552C12.0094 3.39608 12.0199 3.42707 12.0308 3.45847C12.3017 4.23942 12.8129 5.26318 13.7746 6.22485C14.7363 7.18653 15.76 7.69774 16.541 7.96868C16.5722 7.97953 16.6031 7.98998 16.6335 8.00006L17.3729 7.26072Z'
        fill='#BDC1C4'
      />
    </svg>
  )
}

export default PencilDisabled
