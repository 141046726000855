// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
import React, { ReactElement, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tooltip } from '@mui/material'
import Highlight from 'react-highlighter'
import InputElement from '../../app/components/LocalElements/Input/InputMUI.jsx'
import FilterIcon from '../../img/icon/FilterIcon.jsx'
import JCR from './JournalContentRow.tsx'
import { IModelJournalPersonSettings } from '../types.ts'
import css from '../styles.css.ts'

import { changePersonData } from '../../store/slices/admin/admin.js'

const { ContentRow } = css

const PersonJournals: React.FC = (): ReactElement => {
  const dispatch = useDispatch()

  const [showNameFilter, setShowNameFilter] = useState<boolean>(false)
  const [nameFilter, setNameFilter] = useState<string>('')

  const userRole = useSelector((state: any) => state.headerReducer.role)
  const masterArshine = useSelector((state: any) => state.journalReducer.masterArshine)
  const journalsPersonalData = useSelector(
    (state: any) => state.adminReducer.journalSettings.personalData,
  )

  return (
    <React.Fragment>
      <ContentRow gap={20} mb={12}>
        <span style={{ fontWeight: 600, display: 'flex', flexFlow: 'row', width: '25%' }}>
          <span>Наименование документа</span>
        </span>
        <span style={{ fontWeight: 600, display: 'flex', flexFlow: 'row', width: '15%' }}>
          <span>Дата создания</span>
        </span>
        <span style={{ fontWeight: 600, display: 'flex', flexFlow: 'row', width: '15%' }}>
          <span>Учетный номер</span>
        </span>
        <span
          style={{
            fontWeight: 600,
            display: 'flex',
            flexFlow: 'row',
            alignItems: 'center',
            width: '30%',
          }}
        >
          {showNameFilter && (
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                alignItems: 'center',
                width: '100%',
                backgroundColor: 'white',
              }}
            >
              <InputElement
                type={'text'}
                placeholder={'Начните вводить имя или фамилию'}
                label={'Поиск ответственного за документ'}
                value={nameFilter}
                notRequired={true}
                actions={{
                  change: setNameFilter,
                }}
                pattern={undefined}
                className={undefined}
                length={undefined}
                style={undefined}
                multiline={undefined}
                minRows={undefined}
                maxRows={undefined}
                sx={undefined}
              />
            </div>
          )}
          {!!!showNameFilter && <span>Ф. И. О. ответственного за документ</span>}
          {!!!showNameFilter && (
            <Tooltip
              title='Владелец комплекта СП, чьи данные передаются во ФГИС Аршин и ФСА'
              placement='top'
            >
              <span style={{ display: 'block' }}>
                <i
                  className='fas fa-question-circle'
                  style={{ marginLeft: '6px', cursor: 'pointer', color: '#0084E2' }}
                />
              </span>
            </Tooltip>
          )}
          <span
            style={{
              display: 'block',
              left: '100%',
              cursor: 'pointer',
              position: 'absolute',
              marginLeft: '-28px',
            }}
            onClick={() => setShowNameFilter(prev => !!!prev)}
          >
            <FilterIcon />
          </span>
        </span>
        <span style={{ fontWeight: 600, display: 'block', width: '15%' }}></span>
      </ContentRow>
      <ContentRow mb={12}>
        <span
          style={{
            display: 'block',
            position: 'relative',
            width: '100%',
            height: '1px',
            backgroundColor: '#d7ddea',
            opacity: 0.8,
          }}
        />
      </ContentRow>
      {journalsPersonalData &&
        journalsPersonalData
          .filter(
            (journalItem: IModelJournalPersonSettings) => ~journalItem.fullname.indexOf(nameFilter),
          )
          .filter((journalItem: IModelJournalPersonSettings) => {
            if (userRole !== 'super' && userRole !== 'admin') {
              if (journalItem.arshin_master_id === masterArshine) {
                return journalItem
              }
            } else if (userRole === 'super' || userRole === 'admin') {
              return journalItem
            }
          })
          .map((journalItem: IModelJournalPersonSettings, index: number): ReactElement => {
            const localIndex = index

            return (
              <JCR
                context={'person'}
                contextIndex={localIndex}
                name={'Персональный журнал инженера по метрологии'}
                date={{
                  value: journalItem.create_date,
                  action: (date: { format: (arg0: string) => any }): void => {
                    dispatch(
                      changePersonData({
                        index: localIndex,
                        value: 'create_date',
                        label: date.format('YYYY-MM-DD'),
                      }),
                    )
                  },
                }}
                number={journalItem.account_number !== null ? journalItem.account_number : ''}
                executor={
                  <Highlight matchStyle={{ backgroundColor: '#e5f3fc' }} search={nameFilter}>
                    {journalItem.fullname}
                  </Highlight>
                }
                executorControllable={false}
                actions={false}
                gap={20}
                mb={localIndex !== journalsPersonalData.length - 1 ? 12 : 0}
              />
            )
          })}
    </React.Fragment>
  )
}

export default PersonJournals
