import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'

const DataTransfer = props => {
  const { doChecked, handleDoChecked } = props
  const { width } = useWindowDimensions()

  return (
    <React.Fragment>
      <h2 className='M__Customer__title' style={{ marginBottom: '14px' }}>
        Передача данных в федеральные системы
      </h2>

      <div
        className='flexContainer'
        style={
          width > 1050
            ? { alignItems: 'center', gap: '6px' }
            : { alignItems: 'center', gap: '10px', flexFlow: 'row nowrap' }
        }
      >
        <Checkbox checked={doChecked} onChange={handleDoChecked} id='doChecked' />
        <label for='doChecked' style={{ fontSize: '15px', lineHeight: '24px' }}>
          Одобрить передачу данных из протокола во ФГИС Аршин и ФСА
        </label>
      </div>
    </React.Fragment>
  )
}

export default DataTransfer
