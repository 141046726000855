// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Selector from '../LocalElements/Selector/reactSelect/Selector'
import { changeData } from '../../../store/slices/app/views/cases'
import { LabelFrame } from '../../pages/Cases/style'

const CheckPlace = () => {
  const dispatch = useDispatch()
  const methodPlaces = useSelector(state => state.casesReducer.createData.methodPlaces)

  const options = [
    { value: 'labaratory', label: 'Лабораторная поверка' },
    { value: 'field', label: 'Выездная поверка' },
  ]

  const getValue = selectedOptions => {
    const selectedIds = selectedOptions.map(options => options.value)
    dispatch(
      changeData({
        value: 'methodPlaces',
        label: selectedIds,
      }),
    )

    dispatch(
      changeData({
        value: 'methodPoints',
        label: [],
      }),
    )

    if (!selectedOptions) {
      dispatch(
        changeData({
          value: 'methodPlaces',
          label: [],
        }),
      )

      dispatch(
        changeData({
          value: 'methodPoints',
          label: [],
        }),
      )
    }
  }

  return (
    <LabelFrame>
      <Selector
        placeholder={'Выберите необходимые пункты'}
        isRequired={true}
        label={{
          text: 'Способ проведения',
          topBg: 'white',
          bottomBg: 'white',
        }}
        isClearable={false}
        isSearchable={false}
        isMulti={true}
        options={options}
        onChange={getValue}
        value={options.filter(option => methodPlaces.includes(option.value))}
        noOptionsMessage={() => 'Вы выбрали все доступные пункты для этой методики'}
      />
    </LabelFrame>
  )
}

export default CheckPlace
