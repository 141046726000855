// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Selector from '../../../LocalElements/Selector/reactSelect/Selector'
import R from '../../../../../services/app/request.service'
import {
  setMeasurementId,
  setMethodId,
  resetMethodId,
  changeCase,
  resetCase,
} from '../../../../../store/slices/app/views/protocols'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import theme from '../../../../../utils/theme.ts'
import FilterCompany from '../../../../components/LocalElements/Filter/FilterCompany.jsx'

const Method = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const userRole = useSelector(state => state.headerReducer.role)

  const [measurement, setMeasurement] = useState([])
  const [method, setMethod] = useState([])
  const [cases, setCases] = useState([])

  const companyId = useSelector(state => state.companyIdReducer.companyId)
  const measurementId = useSelector(state => state.protocolReducer.measurementId)
  const methodId = useSelector(state => state.protocolReducer.methodId)
  const caseId = useSelector(state => state.protocolReducer.case.caseId)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const optionsMeasurement = measurement
    ? measurement.map(item => ({
        value: item.id,
        label: item.name,
      }))
    : []

  const getValueMeasurement = selectedOption => {
    dispatch(setMeasurementId(selectedOption ? selectedOption.value : ''))
    dispatch(resetMethodId())
    dispatch(resetCase())
  }

  useEffect(() => {
    ;(async () => {
      const { data: listMeasurement } = await R.getMeasurementItem()
      const measurement = listMeasurement.measurements
      setMeasurement(measurement)
    })()
  }, [])

  useEffect(() => {
    if (measurement.length === 1) {
      const singleMeasurement = measurement[0]
      dispatch(setMeasurementId(singleMeasurement.id))
    }
  }, [measurement, dispatch])

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const optionsMethod = method
    ? method.map(item => ({
        value: item.id,
        label: item.name,
      }))
    : []

  const getValueMethod = selectedOption => {
    dispatch(setMethodId(selectedOption ? selectedOption.value : ''))
    dispatch(resetCase())
  }

  useEffect(() => {
    if (measurementId) {
      ;(async () => {
        const { data: listMethod } = await R.getMethodItem(
          `?company_id=${companyId}&measurement_id=${measurementId}`
        )

        false && console.log(listMethod)

        const method = listMethod.data
        setMethod(method)
      })()
    }
  }, [companyId, measurementId])

  useEffect(() => {
    if (method.length === 1) {
      const singleMethod = method[0]
      dispatch(setMethodId(singleMethod.id))
    }
  }, [method, dispatch])

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const optionsCases = cases
    ? cases.map(item => ({
        value: item.id,
        label: (
          <span
            onClick={() => {
              !item.is_usable && navigate('/metriva/cases')
            }}
            style={{
              color: item.is_usable ? 'inherit' : theme.palette.background.error,
              fontSize: '14px',
            }}
          >
            {item.inner_name} -{' '}
            {item.is_usable ? 'доступен' : 'недоступен, cм. причины в разделе «Комплекты СП»'}
          </span>
        ),
        isDisabled: !item.is_usable,
      }))
    : []

  const getValueCases = selectedOption => {
    if (selectedOption) {
      dispatch(changeCase({ value: 'caseId', label: selectedOption.value }))
      const selectedComplect = cases.find(item => item.id === selectedOption.value)

      dispatch(
        changeCase({
          value: 'pointMethod',
          label: selectedComplect.points.map(point => ({
            id: point.id,
            name: point.name,
            additional: point.additional,
          })),
        })
      )

      dispatch(
        changeCase({
          value: 'method',
          label: selectedComplect.verification_methods,
        })
      )

      dispatch(
        changeCase({
          value: 'verificationType',
          label: selectedComplect.verification_types,
        })
      )
    }
  }

  useEffect(() => {
    if (methodId) {
      ;(async () => {
        const { data: listCases } = await R.getCasesItem(
          `?company_id=${companyId}&method_id=${methodId}`
        )
        const data = listCases.data
        setCases(data)
      })()
    }
  }, [companyId, methodId])

  useEffect(() => {
    const usableCases = cases.filter(item => item.is_usable)

    if (usableCases.length === 1) {
      const singleCase = usableCases[0]
      dispatch(changeCase({ value: 'caseId', label: singleCase.id }))
      dispatch(
        changeCase({
          value: 'pointMethod',
          label: singleCase.points.map(point => ({
            id: point.id,
            name: point.name,
            additional: point.additional,
          })),
        })
      )
      dispatch(
        changeCase({
          value: 'method',
          label: singleCase.verification_methods,
        })
      )
      dispatch(
        changeCase({
          value: 'verificationType',
          label: singleCase.verification_types,
        })
      )
    } else if (usableCases.length > 1) {
      const mainCase = usableCases.find(item => item.is_main_status === 'main')

      if (mainCase) {
        dispatch(changeCase({ value: 'caseId', label: mainCase.id }))
        dispatch(
          changeCase({
            value: 'pointMethod',
            label: mainCase.points.map(point => ({
              id: point.id,
              name: point.name,
              additional: point.additional,
            })),
          })
        )
        dispatch(
          changeCase({
            value: 'method',
            label: mainCase.verification_methods,
          })
        )
        dispatch(
          changeCase({
            value: 'verificationType',
            label: mainCase.verification_types,
          })
        )
      }
    }
  }, [cases, dispatch])

  const noOptionsMessageCases = methodId
    ? 'Комплекты СП не найдены'
    : 'Сначала выберите методику поверки'

  return (
    <div className='flexContainerWithGap' style={width <= 1050 ? { margin: '0px' } : {}}>
      {userRole === 'super' && (
        <div className='width24Point5Percent'>
          <FilterCompany />
        </div>
      )}

      <div className='width24Point5Percent'>
        <Selector
          placeholder={'Область измерения'}
          onChange={getValueMeasurement}
          options={optionsMeasurement}
          isClearable={false}
          isSearchable={true}
          noOptionsMessage={() => 'Выберите область измерения'}
          value={
            measurementId ? optionsMeasurement.find(option => option.value === measurementId) : null
          }
        />
      </div>

      <div className='width24Point5Percent'>
        <Selector
          placeholder={'Методика поверки'}
          onChange={getValueMethod}
          options={optionsMethod}
          isClearable={false}
          isSearchable={true}
          noOptionsMessage={() => 'Сначало выберите область измерения'}
          value={methodId ? optionsMethod.find(option => option.value === methodId) : null}
        />
      </div>

      <div className='width24Point5Percent'>
        <Selector
          placeholder={'Комплект СП'}
          onChange={getValueCases}
          options={optionsCases}
          isClearable={false}
          isSearchable={true}
          noOptionsMessage={() => noOptionsMessageCases}
          value={caseId ? optionsCases.find(option => option.value === caseId) : null}
        />
      </div>
    </div>
  )
}

export default Method
