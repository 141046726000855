import React, { ChangeEvent, ReactElement, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Input from '../LocalElements/Input/InputMUI'
import DatePicker from '../LocalElements/DatePicker/DatePicker'
import { Button, Radio, RadioGroup, FormControlLabel } from '@mui/material'

import {
  changeEtalonAttestationsForm,
  resetEtalonAttestationsForm,
  changeEtalonAttestations,
} from '../../../store/slices/app/views/etalon'
import { setOpen } from '../../../store/slices/app/comps/bottomContainer'
import { IModelEtalon__KitAttestationsItem, IModelEtalonCreate } from '../../types'

const CreateAttestationItem: React.FC = (): ReactElement => {
  const dispatch = useDispatch()

  const journalId = useSelector((state: any) => state.journalReducer.journalId)

  const formData: IModelEtalon__KitAttestationsItem = useSelector(
    (state: any) => state.etalonReducer.createData.kitAttestationsForm
  )
  const etalonCreateData: IModelEtalonCreate = useSelector(
    (state: any) => state.etalonReducer.createData
  )

  const addRow = (): void => {
    const verificationData: any = {
      place: formData.place,
      date: formData.date,
      interval: Number(etalonCreateData.interval_verifications),
      link: formData.link,
    }

    if (!journalId) {
      verificationData.certificate_number = formData.certificate_number
      verificationData.is_suitable = formData.is_suitable
    }

    dispatch(changeEtalonAttestations(verificationData))
    dispatch(resetEtalonAttestationsForm())
    dispatch(setOpen(false))
  }

  const validate = useMemo(() => {
    if (!journalId) {
      return (
        formData.place &&
        formData.date &&
        etalonCreateData.interval_verifications &&
        formData.certificate_number &&
        formData.link
      )
    }

    return (
      formData.place && formData.date && etalonCreateData.interval_verifications && formData.link
    )
  }, [
    formData.place,
    formData.date,
    etalonCreateData.interval_verifications,
    formData.certificate_number,
    formData.link,
    journalId,
  ])

  return (
    <React.Fragment>
      <div className='flexContainerWithGap' style={{ marginTop: '10px', marginBottom: '22px' }}>
        <div className='width50Percent'>
          <Input
            className={'input'}
            type={'text'}
            placeholder={'Введите место проведения'}
            label={'Место проведения (наименование организации)'}
            value={formData.place}
            actions={{
              change: (param: string) =>
                dispatch(changeEtalonAttestationsForm({ value: 'place', label: param })),
            }}
            pattern={undefined}
            length={undefined}
            style={undefined}
            multiline={undefined}
            minRows={undefined}
            maxRows={undefined}
            sx={undefined}
          />
        </div>
        <div className='width50Percent' style={{ display: 'flex', gap: '10px' }}>
          <div style={{ width: journalId ? '100%' : '50%' }}>
            <DatePicker
              isRequired={true}
              selectedDate={formData.date ? formData.date : null}
              dateChange={(date: { format: (arg0: string) => any }) =>
                dispatch(
                  changeEtalonAttestationsForm({
                    value: 'date',
                    label: date.format('YYYY-MM-DD'),
                  })
                )
              }
              label='Дата проведения'
            />
          </div>
          {!journalId && (
            <div className='width50Percent' style={{ paddingLeft: '10px' }}>
              <span>Заключение аттестации</span>
              <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='controlled-radio-buttons-group'
                value={formData.is_suitable}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  dispatch(
                    changeEtalonAttestationsForm({
                      value: 'is_suitable',
                      label: event.target.value === 'true',
                    })
                  )
                }}
                style={{
                  flexDirection: 'row',
                  fontFamily: "'Wix Madefor Display', sans-serif",
                  color: '#123532',
                  fontSize: '16px',
                  fontWeight: '400',
                  marginTop: '0px',
                  paddingLeft: '5px',
                }}
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '22px !important',
                        },
                      }}
                    />
                  }
                  label='Пригоден'
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
                <FormControlLabel
                  value={false}
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '22px !important',
                        },
                      }}
                    />
                  }
                  label='Непригоден'
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
              </RadioGroup>
            </div>
          )}
        </div>
      </div>
      <div className='flexContainerWithGap' style={{ marginBottom: '14px' }}>
        <div className='width50Percent'>
          <Input
            className={'input'}
            type={'text'}
            placeholder={'Введите № свидетельства или извещения'}
            label={'№ свидетельства или извещения'}
            value={formData.certificate_number}
            actions={{
              change: (param: string) =>
                dispatch(
                  changeEtalonAttestationsForm({ value: 'certificate_number', label: param })
                ),
            }}
            pattern={undefined}
            length={undefined}
            style={undefined}
            multiline={undefined}
            minRows={undefined}
            maxRows={undefined}
            sx={undefined}
          />
        </div>
        {!journalId && (
          <div className='width50Percent'>
            <Input
              className={'input'}
              type={'text'}
              placeholder={'Вставьте необходимую ссылку'}
              label={'Ссылка на результаты аттестации (испытаний) в Аршин'}
              value={formData.link}
              actions={{
                change: (param: string) =>
                  dispatch(changeEtalonAttestationsForm({ value: 'link', label: param })),
              }}
              pattern={undefined}
              length={undefined}
              style={undefined}
              multiline={undefined}
              minRows={undefined}
              maxRows={undefined}
              sx={undefined}
            />
          </div>
        )}

        {journalId && <div className='width50Percent'></div>}
      </div>
      <div className='flexContainerWithGap' style={{ justifyContent: 'flex-end' }}>
        <div style={{ width: '240px' }}>
          <Button
            variant='contained'
            onClick={addRow}
            disabled={validate ? false : true}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              margin: 0,
              borderRadius: '12px',
              width: '100%',
            }}
          >
            {'Добавить'}
          </Button>
        </div>
        <div style={{ width: '240px' }}>
          <Button
            variant='contained'
            onClick={() => dispatch(resetEtalonAttestationsForm())}
            disabled={false}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              margin: 0,
              borderRadius: '12px',
              width: '100%',
              filter: 'grayscale(1)',
            }}
          >
            Сбросить все значения
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateAttestationItem
