import React from 'react'

const HelloScreen = () => {
  return (
    <div className='login_wrapper'>
      <div className='preloader-container'>
        <div className='rotating-circle'>
          <div className='dot' style={{ '--i': 1 }}></div>
          <div className='dot' style={{ '--i': 2 }}></div>
          <div className='dot' style={{ '--i': 3 }}></div>
          <div className='dot' style={{ '--i': 4 }}></div>
        </div>
        <p className='loading-text'>Пожалуйста подождите, идет загрузка данных</p>
      </div>
    </div>
  )
}

export default HelloScreen
