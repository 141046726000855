// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Slider,
  Drawer,
  styled,
  Button,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import FilterIcon from '../../../../img/icon/FilterIcon.jsx'
import Selector from '../Selector/reactSelect/Selector.jsx'
import Input from '../../../../ui/input/index.tsx'
import theme from '../../../../utils/theme.ts'

const CustomSliderFromMaterialUI = styled(Slider)(({ theme }) => ({
  color: '#007bff',
  height: 5,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#F8F9FC',
    boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -6,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
  },
  '& .MuiSlider-mark': {
    backgroundColor: 'transparent',
  },
  '& .MuiSlider-markLabel': {
    fontFamily: '"Wix Madefor Display", sans-serif',
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: 4,
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    boxShadow: 'inset 0px 0px 4px -2px #000',
    backgroundColor: '#d0d0d0',
  },
}))

const CustomToolbar = ({
  columns = [],
  actions = {
    static: null,
    dinamic: null,
  },
  noCreating = false,
  selectedRowCount,
  filters,
  setFilters,
  rowData = [],
  filtersVariant = 'client',
  addButtonCallback = () => {},
  setIsDrawerOpenServer = () => {},
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const handleChange = field => value => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [field]: value,
    }))
  }

  const handleSearchChange = param => {
    const value = param
    setSearchQuery(value)

    setFilters(prevFilters => ({
      ...prevFilters,
      query: value,
    }))
  }

  const handleClearFilters = () => {
    const clearedFilters = {}
    columns.forEach(col => {
      if (!col.disableFilter) {
        clearedFilters[col.field] = ''
      }
    })

    clearedFilters.query = ''
    setSearchQuery('')
    setFilters(clearedFilters)
  }

  const renderFilterControl = (col, isLastInRow = false) => {
    if (col.disableFilter) return null

    const { field, type } = col
    const uniqueValues =
      rowData.length > 0 ? [...new Set(rowData.map(row => row[field] ?? ''))] : []

    if (type === 'number') {
      const validValues = uniqueValues.filter(value => typeof value === 'number')
      const minValue = validValues.length > 0 ? Math.min(...validValues) : 0
      const maxValue = validValues.length > 0 ? Math.max(...validValues) : 100
      const currentValue = filters[field] || [minValue, maxValue]

      return (
        <Grid item xs={12} sm={6} md={4} lg={isLastInRow ? true : 4} key={field}>
          <div className='right-side-container-block'>
            <span
              style={{
                display: 'block',
                width: '100%',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              {typeof col.headerName === 'string' ? col.headerName : col.headerNameAlt}
            </span>
            <div
              style={{
                width: '100%',
                padding: '0px 14px',
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'space-around',
              }}
            >
              <CustomSliderFromMaterialUI
                sx={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}
                size='medium'
                value={currentValue}
                onChange={(e, newValue) => handleChange(field)(newValue)}
                valueLabelDisplay='off'
                min={minValue}
                max={maxValue}
                marks={Array.from({ length: maxValue - minValue + 1 }, ($$, i) => ({
                  value: minValue + i,
                  label: `${minValue + i}`,
                }))}
              />
            </div>
          </div>
        </Grid>
      )
    } else if (type === 'boolean') {
      return (
        <Grid item xs={12} sm={6} md={4} lg={isLastInRow ? true : 4} key={field}>
          <div className='right-side-container-block'>
            <span
              style={{
                display: 'block',
                width: '100%',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              {typeof col.headerName === 'string' ? col.headerName : col.headerNameAlt}
            </span>
            <RadioGroup
              row
              value={filters[field] || ''}
              onChange={e => handleChange(field)(e.target.value)}
            >
              <FormControlLabel value='true' control={<Radio />} label='Да' />
              <FormControlLabel value='false' control={<Radio />} label='Нет' />
              <FormControlLabel value='' control={<Radio />} label='Все' />
            </RadioGroup>
          </div>
        </Grid>
      )
    } else if (type === 'arrayString') {
      return (
        <Grid item xs={12} sm={6} md={4} lg={isLastInRow ? true : 4} key={field}>
          <div className='right-side-container-block'>
            <span
              style={{
                display: 'block',
                width: '100%',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              {typeof col.headerName === 'string' ? col.headerName : col.headerNameAlt}
            </span>
            <div style={{ width: '100%' }}>
              <Selector
                placeholder={'Выберите нужный параметр'}
                options={[
                  ...new Set(
                    uniqueValues
                      .map(value => {
                        if (~value.indexOf('|')) {
                          return value.split(' | ')
                        }

                        return value
                      })
                      .flat(Infinity),
                  ),
                ].map(value => ({ value, label: value }))}
                onChange={option => handleChange(field)(option?.value ?? '')}
                value={filters[field] ? { value: filters[field], label: filters[field] } : null}
                isClearable
                isSearchable={false}
                noOptionsMessage={() => 'Нет доступных вариантов'}
              />
            </div>
          </div>
        </Grid>
      )
    } else if (uniqueValues.length <= 8 && uniqueValues.length > 0) {
      return (
        <Grid item xs={12} sm={6} md={4} lg={isLastInRow ? true : 4} key={field}>
          <div className='right-side-container-block'>
            <span
              style={{
                display: 'block',
                width: '100%',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              {typeof col.headerName === 'string' ? col.headerName : col.headerNameAlt}
            </span>
            <div style={{ width: '100%' }}>
              <Selector
                placeholder={'Выберите нужный параметр'}
                options={uniqueValues.map(value => ({ value, label: value }))}
                onChange={option => handleChange(field)(option?.value ?? '')}
                value={filters[field] ? { value: filters[field], label: filters[field] } : null}
                isClearable
                isSearchable={false}
                noOptionsMessage={() => 'Нет доступных вариантов'}
              />
            </div>
          </div>
        </Grid>
      )
    } else {
      return (
        <Grid item xs={12} sm={6} md={4} lg={isLastInRow ? true : 4} key={field}>
          <div className='right-side-container-block'>
            <span
              style={{
                display: 'block',
                width: '100%',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              {typeof col.headerName === 'string' ? col.headerName : col.headerNameAlt}
            </span>
            <Input
              placeholder='Введите нужный параметр'
              variant='outlined'
              size='small'
              value={filters[field] || ''}
              notRequired
              actions={{ change: handleChange(field) }}
            />
          </div>
        </Grid>
      )
    }
  }

  return (
    <React.Fragment>
      <Drawer
        anchor='right'
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: 400,
            padding: '20px',
            boxSizing: 'border-box',
          },
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h4
              style={{
                display: 'block',
                marginBottom: '20px',
                fontSize: '18.8px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              Фильтры
            </h4>
            <span
              onClick={() => setIsDrawerOpen(false)}
              style={{
                cursor: 'pointer',
                marginTop: '-12px',
              }}
            >
              <CloseIcon sx={{ cursor: 'pointer' }} />
            </span>
          </div>
          <section
            style={{
              display: 'flex',
              flexFlow: 'row wrap',
              width: '100%',
              position: 'relative',
              gap: '16px',
            }}
          >
            {columns
              .filter(col => col.field !== 'actions' && !col.disableFilter)
              .map((col, index, array) => (
                <div style={{ width: '100%' }}>
                  {renderFilterControl(col, index === array.length - 1)}
                </div>
              ))}
            <Button
              variant='outlined'
              onClick={handleClearFilters}
              fullWidth
              sx={{
                fontFamily: "'Wix Madefor Display',sans-serif",
                height: '54.4px',
                borderRadius: '12px',
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.secondary,
                fontSize: '15px',
                fontWeight: 500,
                textTransform: 'none',
                border: `1px solid ${theme.palette.text.secondary}`,
              }}
            >
              Сбросить фильтры
            </Button>
          </section>
        </div>
      </Drawer>

      <div
        style={{
          width: '100%',
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          gap: '10px',
          marginBottom: '14px',
        }}
      >
        {(actions.static !== null || actions.dinamic !== null) && (
          <React.Fragment>
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                alignItems: 'center',
                width: '100%',
                height: '60px',
                position: 'absolute',
                gap: '20px',
                padding: '0px 20px',
                top: 0,
                backgroundColor: 'rgba(234, 237, 243, 0.46)',
              }}
            >
              {actions.static ? actions.static : <React.Fragment></React.Fragment>}
              {actions.dinamic && (
                <span
                  style={{
                    width: '1px',
                    height: '100%',
                    opacity: 0.44,
                    background: 'linear-gradient(to bottom, transparent, gray, transparent)',
                  }}
                />
              )}
              {actions.dinamic && (
                <span style={{ fontSize: '.88rem', opacity: 0.8 }}>
                  Выбрано строк - {selectedRowCount}
                </span>
              )}
              {actions.dinamic && (
                <span
                  style={{
                    width: '1px',
                    height: '100%',
                    opacity: 0.44,
                    background: 'linear-gradient(to bottom, transparent, gray, transparent)',
                  }}
                />
              )}
              {actions.dinamic ? actions.dinamic : <React.Fragment></React.Fragment>}
            </div>
          </React.Fragment>
        )}
        <div
          style={{
            display: 'flex',
            flexFlow: 'row',
            width: '100%',
            gap: '10px',
            marginTop: actions.static !== null || actions.dinamic !== null ? '62px' : '0px',
          }}
        >
          {!!!noCreating && (
            <div style={{ flexGrow: 1 }}>
              <Button
                variant='contained'
                onClick={addButtonCallback}
                sx={{
                  fontFamily: "'Wix Madefor Display',sans-serif",
                  height: '54.4px',
                  width: '100%',
                  borderRadius: '12px',
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.background.default,
                  fontSize: '15px',
                  fontWeight: 500,
                  textTransform: 'capitalize',
                }}
              >
                Добавить
              </Button>
            </div>
          )}
          <div style={{ flexGrow: !!!noCreating ? 8 : 9 }}>
            <Input
              placeholder='Введите текст для поиска'
              variant='outlined'
              size='small'
              value={searchQuery}
              actions={{
                change: handleSearchChange,
              }}
              notRequired={true}
            />
          </div>
          <div style={{ flexGrow: 1, position: 'relative', overflow: 'hidden' }}>
            <Button
              variant='outlined'
              startIcon={<FilterIcon />}
              onClick={() => {
                filtersVariant === 'client' ? setIsDrawerOpen(true) : setIsDrawerOpenServer()
              }}
              sx={{
                fontFamily: "'Wix Madefor Display',sans-serif",
                height: '54.4px',
                width: '100%',
                borderRadius: '12px',
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.secondary,
                fontSize: '15px',
                fontWeight: 500,
                textTransform: 'capitalize',
                border: `1px solid ${theme.palette.text.secondary}`,
              }}
            >
              Фильтры
            </Button>
            <span
              style={{
                display: 'block',
                position: 'absolute',
                backgroundColor: '#1976d2',
                borderRadius: '12px',
                width: '24px',
                height: '24px',

                color: 'white',
                fontSize: '11px',
                textAlign: 'center',
                lineHeight:
                  Object.values(filters).filter(value => value !== '').length > 1
                    ? '22.8px'
                    : '23px',

                top: '0%',
                left: '100%',
                marginLeft: '-30px',
                marginTop: '6px',

                transition: 'all 500ms',
                transform:
                  !!!filters ||
                  Object.values(filters).length === 0 ||
                  Object.values(filters).every(value => value === '')
                    ? 'translateX(40px)'
                    : 'translateX(0px)',
              }}
            >
              {filters ? Object.values(filters).filter(value => value !== '').length : null}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CustomToolbar
