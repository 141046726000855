const DeleteIcon = ({ color = '#899298', width = 22, height = 22, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 21 21'
      onClick={onClick}
    >
      <path
        d='M2.82227 5.16168C2.82227 4.77815 3.11008 4.46724 3.46512 4.46724L5.68532 4.46687C6.12645 4.4548 6.51561 4.1518 6.66572 3.70355C6.66966 3.69176 6.6742 3.67722 6.69048 3.62448L6.78614 3.3144C6.84468 3.12427 6.89569 2.95864 6.96705 2.81059C7.24901 2.2257 7.77066 1.81954 8.37349 1.71555C8.52608 1.68923 8.68767 1.68934 8.87315 1.68947H11.7715C11.957 1.68934 12.1186 1.68923 12.2712 1.71555C12.874 1.81954 13.3957 2.2257 13.6776 2.81059C13.749 2.95864 13.8 3.12427 13.8585 3.3144L13.9542 3.62448C13.9705 3.67722 13.975 3.69176 13.979 3.70355C14.1291 4.1518 14.5954 4.45517 15.0366 4.46724H17.1794C17.5344 4.46724 17.8223 4.77815 17.8223 5.16168C17.8223 5.54521 17.5344 5.85612 17.1794 5.85612H3.46512C3.11008 5.85612 2.82227 5.54521 2.82227 5.16168Z'
        fill={color}
      />
      <path
        d='M10.2029 18.356H10.8587C13.1149 18.356 14.243 18.356 14.9765 17.6366C15.71 16.9173 15.7851 15.7373 15.9352 13.3774L16.1514 9.97699C16.2328 8.69652 16.2736 8.05629 15.9056 7.65058C15.5376 7.24487 14.9162 7.24487 13.6734 7.24487H7.38814C6.14535 7.24487 5.52396 7.24487 5.15599 7.65058C4.78803 8.05629 4.82874 8.69652 4.91018 9.97699L5.12643 13.3774C5.27652 15.7373 5.35156 16.9173 6.08505 17.6366C6.81854 18.356 7.94666 18.356 10.2029 18.356Z'
        fill={color}
      />
    </svg>
  )
}

export default DeleteIcon
