// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SearchQuery from '../../LocalElements/Search/SearchQuery'

import { Typography, useMediaQuery, Collapse } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PencilIcon from '../../../../img/icon/PencilIcon'
import DeleteWithTimerIcon from '../../../../img/icon/DeleteWithTimerIcon.jsx'
import RestoreIcon from '../../../../img/icon/RestoreIcon'
import Fab from '@mui/material/Fab'
import Table from '../../LocalElements/Table/Table'
import RightSideContainer from '../../LocalElements/RightSideContainer/RightSideContainer'
import SelectorJournals from '../Select/SelectJournal'
import Selector from '../../LocalElements/Selector/reactSelect/Selector'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'

import Pdf from '../../../../img/icon/PdfIcon'
import Csv from '../../../../img/icon/CsvIcon'
import FilterIcon from '../../../../img/icon/FilterIcon'
import Excel from '../../../../img/icon/Excel'
import Tooltip from '../../../../ui/tooltip/index'
import Button from '../../../../ui/button/index'
import RadioButton from '../../../../ui/radio-button/index'
import theme from '../../../../utils/theme.ts'

import {
  setSearchQuery,
  setJournalIdFilter,
  setEntryId,
  setJournalId,
  changeArchived,
  changeArchiveFilter,
  resetArchiveFilter,
} from '../../../../store/slices/app/views/journals'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer'
import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import {
  setOpen as setOpenModal,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  setBD,
  showBA,
  showBD,
  resetState,
} from '../../../../store/slices/app/comps/modal'
import {
  refreshConditions,
  refreshPersonalMetrolog,
  refreshKitVerifications,
  refreshKitPassport,
  refreshReceiveds,
  refreshTickets,
  refreshIssueCase,
  refreshEntriesArchive,
  refreshArchives,
} from '../../../../store/slices/app/controlers/updater'

import { Container, CountLabel, Filter, IconFrame, Wrapper, FrameFilter } from '../style'

import R from '../../../../services/app/request.service'

const TableArchive = () => {
  const isMobile = useMediaQuery(theme.breakpoints.up('lg'))
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userRole = useSelector(state => state.headerReducer.role)
  const data = useSelector(state => state.journalReducer.dataArchive)
  const journalIdFilter = useSelector(state => state.journalReducer.journalIdFilter)
  const searchQuery = useSelector(state => state.journalReducer.searchQuery)
  const archiveFromDate = useSelector(state => state.journalReducer.archiveFilter.archiveFromDate)
  const archiveToDate = useSelector(state => state.journalReducer.archiveFilter.archiveToDate)
  const complete = useSelector(state => state.journalReducer.archiveFilter.complete)
  const archive = useSelector(state => state.journalReducer.archiveFilter.archive)

  const selectedRows = useSelector(state => state.journalReducer.archived.selectedRows)
  const deletedDate = useSelector(state => state.journalReducer.archived.deletedDate)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const currentDate = new Date().toLocaleDateString('ru-RU').replace(/\./g, '-')
  const [selectedJournalId, setSelectedJournalId] = useState([])

  const count = useMemo(() => {
    let filtersCount = 0

    if (complete) filtersCount++
    if (archive) filtersCount++
    if (journalIdFilter) filtersCount++
    if (archiveFromDate) filtersCount++
    if (archiveToDate) filtersCount++

    if (!complete && !archive && !journalIdFilter && !archiveFromDate && !archiveToDate)
      filtersCount = 0
    return filtersCount
  }, [archiveFromDate, archiveToDate, complete, archive, journalIdFilter])

  const journalId = useSelector(state => state.journalReducer.journalId)

  const handleResetFilter = () => {
    dispatch(resetArchiveFilter())
    dispatch(setJournalIdFilter(''))
  }

  const handleShowClick = param => {
    if (param.row.DeletionStatus) return
    dispatch(setEntryId(+param.id))
    dispatch(setJournalId(+param.row.TypeId))
    dispatch(refreshEntriesArchive())
    navigate('/metriva/journals/archive_entries')
  }

  const handleEditClick = param => {
    navigate(`/metriva/journals/${param}/edit_archives`)
  }

  const handleUnarchive = async param => {
    const { status } = await R.unarchiveJournals(companyId, param)

    if (status === 200) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage('Документ был успешно восстановлен'))
      dispatch(setOpenModal(false))
      dispatch(refreshArchives())
      dispatch(resetState())
    } else if (status === 404) {
      dispatch(setOpenModal(true))
      dispatch(setTitle('Cкачивание PDF недоступно'))
      dispatch(
        setChildren(
          selectedRows.length === 1 ? (
            <span>
              Внимание! Скачивание PDF выбранных документов недоступно так как они были удалены из
              архива (см. колонку “Дата уничтожения”).
            </span>
          ) : (
            <span>
              Внимание! Скачивание PDF выбранных документов недоступно так как они были удалены из
              архива (см. колонку “Дата уничтожения”).
            </span>
          )
        )
      )
      dispatch(showBA(false))
      dispatch(setBD('Закрыть'))
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(`Ошибка при восстановлении документа - ${status}`))
    }
  }

  const handleDelete = param => async deletedDateParam => {
    const { status } = await R.sheduleDeleteJournals(companyId, param, {
      deletion_scheduled_date: deletedDateParam,
    })

    if (status === 200) {
      dispatch(setOpenModal())
      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage('Документ был поставлен на удаление'))
      dispatch(refreshArchives())
      dispatch(resetState())
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(`Ошибка при планировании удаления документа - ${status}`))
    }
  }

  const changeSearchQuery = param => {
    dispatch(setSearchQuery(param))
  }

  const handleDownloadSelectionPdf = async () => {
    const { status, data } = await R.getArchiveEntriesPdf(
      companyId,
      '?' + selectedRows.map(row => `ids[]=${row}`).join('&')
    )

    if (status === 200) {
      let fileName = ''

      if (selectedJournalId.length > 1) {
        fileName = `Zhurnal_registracii_arhivnyh_dokumentov_${currentDate}.zip`
      } else {
        switch (selectedJournalId[0]) {
          case 2:
            fileName = `Zhurnal_registracii_uslovij_hraneniya_sredstv_poverki_(arhiv)_${currentDate}.pdf`
            break
          case 3:
            fileName = `Zhurnal_ucheta_postupivshih_na_poverku_SI_i_provedeniya_poverochnyh_rabot_(arhiv)_${currentDate}.pdf`
            break
          case 4:
            fileName = `Zhurnal_registracii_zayavok_na_poverku_(arhiv)_${currentDate}.pdf`
            break
          case 5:
            fileName = `Zhurnal_vydachi_sredstv_poverki_(arhiv)_${currentDate}.pdf`
            break
          case 6:
            fileName = `Personalnyi_zhurnal_inzhinera_po_metrologii_(arhiv)_${currentDate}.pdf`
            break
          case 7:
            fileName = `Grafik_poverki_sredstv_izmereniya_i_kontrolya_(arhiv)_${currentDate}.pdf`
            break
          case 8:
            const num = rowData.find(item => item.id === selectedRows[0])?.Num || ''
            fileName = `Pasport_sp_${num}_(arhiv)_${currentDate}.pdf`
            break
          default:
            fileName = `Dokumenty__${currentDate}.pdf`
            break
        }
      }

      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      dispatch(setOpenModal(false))
    } else if (status === 500) {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке PDF.'))
    } else {
      dispatch(setOpenModal(true))
      dispatch(setTitle('Cкачивание PDF недоступно'))
      dispatch(
        setChildren(
          <span>
            Внимание! Скачивание PDF выбранных документов недоступно так как они были удалены из
            архива (см. колонку “Дата уничтожения”).
          </span>
        )
      )
      dispatch(showBA(false))
      dispatch(setBD('Закрыть'))
    }
  }

  const handleDownloadPdf = async () => {
    const { status, data } = await R.getArchivePdf(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Dokumenty_(arhiv)_${currentDate}.pdf`)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке PDF.'))
    }
  }

  const handleDownloadCsv = async () => {
    const { status, data } = await R.getArchiveCsv(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Zhurnal_registracii_arhivnyh_dokumentov_${currentDate}.csv`)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке Csv.'))
    }
  }

  const handleDownloadExcel = async () => {
    const { status, data } = await R.getArchiveExcel(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Zhurnal_registracii_arhivnyh_dokumentov_${currentDate}.xlsx`)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке Excel.'))
    }
  }

  let colData = [
    { field: 'Num', headerName: '№ п/п' },
    {
      field: 'IncomingDate',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>Дата переноса в архив</div>
      ),
    },
    {
      field: 'Title',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>Наименование документа</div>
      ),
    },
    { field: 'RegNum', headerName: 'Учетный номер' },
    { field: 'CountList', headerName: 'Количество листов' },
    { field: 'WhoName', headerName: 'ФИО передавшего документ в архив' },
    { field: 'FromArchiveDate', headerName: 'Дата выдачи из архива' },
    { field: 'ToArchiveDate', headerName: 'Дата возврата в архив' },
    { field: 'DestrDate', headerName: 'Дата уничтожения' },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: item => {
        const localItem = item

        return [
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Tooltip title='Восстановить документ'>
              <div
                onClick={() => {
                  dispatch(setTitle('Восстановление документа'))
                  dispatch(setChildren(modalContent))
                  dispatch(showBA(true))
                  dispatch(showBD(true))
                  dispatch(setBA('Восстановить'))
                  dispatch(setBD('Отменить'))
                  dispatch(
                    setConfirmAction(() => {
                      handleUnarchive(+item.row.id)
                    })
                  )
                  dispatch(setOpenModal(true))
                }}
                style={{
                  cursor: 'pointer',
                }}
              >
                <RestoreIcon color='#899298' />
              </div>
            </Tooltip>

            <Tooltip title='Редактировать документ'>
              <div
                onClick={() => {
                  handleEditClick(localItem.row.id)
                }}
                style={{
                  cursor: 'pointer',
                }}
              >
                <PencilIcon />
              </div>
            </Tooltip>

            {localItem.row.DeletionStatus ? (
              <Tooltip title='Документ уже удален'>
                <div>
                  <DeleteWithTimerIcon color='#BDC1C4' />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Назначить дату удаления'>
                <div
                  onClick={() => {
                    dispatch(setTitle('Уничтожение документа'))
                    dispatch(setChildren(modalContentRemove))
                    dispatch(showBA(true))
                    dispatch(showBD(true))
                    dispatch(setBA('Подтвердить'))
                    dispatch(setBD('Отменить'))
                    dispatch(
                      setConfirmAction(param => {
                        handleDelete(+item.row.id)(param)
                      })
                    )
                    dispatch(setOpenModal(true))
                  }}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <DeleteWithTimerIcon />
                </div>
              </Tooltip>
            )}
          </div>,
        ]
      },
    },
  ]

  let rowData =
    data && data[0] !== null
      ? data.map(item => ({
          id: item.id,
          Num: item.number,
          IncomingDate: item.entry_date,
          Title: item?.journal_type_name,
          TypeId: item?.journal_type_id,
          RegNum: item.account_number,
          CountList: item.pages,
          WhoName: item.created_by_name,
          FromArchiveDate: item.issue_date,
          ToArchiveDate: item.return_date,
          DestrDate: item.deletion_scheduled_date,
          DeletionStatus: item.deletion_status,
        }))
      : []

  useEffect(() => {
    return () => {
      dispatch(refreshConditions())
      dispatch(refreshPersonalMetrolog())
      dispatch(refreshKitVerifications())
      dispatch(refreshKitPassport())
      dispatch(refreshReceiveds())
      dispatch(refreshTickets())
      dispatch(refreshIssueCase())
    }
  }, [])

  const modalContent = (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '620px',
        gap: 22,
      }}
    >
      <Typography variant='subtitle2'>
        Вы действительно хотите восстановить выбранный документ? После восстановления, все архивные
        записи будут объединены с записями из вкладки "Актуальная версия документа".
      </Typography>
    </div>
  )

  const modalContentRemove = (
    <section
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '620px',
        gap: 22,
      }}
    >
      <Typography variant='subtitle2'>
        Пожалуйста, выберите дату планового уничтожения документа.
      </Typography>
      <Typography variant='subtitle2'>
        Обратите внимание, после уничтожения документ будет удалён из системы окончательно и
        восстановить его будет невозможно.
      </Typography>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
          gap: 16,
        }}
      >
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexFlow: 'column',
            gap: 9,
          }}
        >
          <Typography variant='subtitle2'>Дата уничтожения документа</Typography>
          <DatePicker
            selectedDate={deletedDate}
            dateChange={date => {
              dispatch(
                changeArchived({
                  value: 'deletedDate',
                  label: date.format('YYYY-MM-DD'),
                })
              )
            }}
          />
        </div>
      </div>
    </section>
  )

  const hasDeletedDocuments = selectedRows.some(selectedId => {
    const matchedRow = rowData.find(row => row.id === selectedId)
    return matchedRow ? matchedRow.DeletionStatus : false
  })

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <RightSideContainer blockTitle={'Фильтры'}>
        <Wrapper>
          <FrameFilter>
            <Typography variant='subtitle2'>Наименование документа</Typography>
            <SelectorJournals isLabel={false} variant='filter' />
          </FrameFilter>

          {false && (
            <FrameFilter>
              <Typography variant='subtitle2' sx={{ opacity: 0.6 }}>
                Передавший в архив
              </Typography>
              <Selector
                placeholder={'Выберите нужного человека'}
                options={[]}
                onChange={() => {}}
                isClearable={true}
                isSearchable={true}
                isDisabled={true}
                value={''}
                noOptionsMessage={() => 'Нет доступных вариантов'}
              />
            </FrameFilter>
          )}

          <FrameFilter>
            <Typography variant='subtitle2'>Дата переноса в архив</Typography>
            <DatePicker
              selectedDate={archiveFromDate}
              dateChange={date =>
                dispatch(
                  changeArchiveFilter({
                    value: 'archiveFromDate',
                    label: date.format('YYYY-MM-DD'),
                  })
                )
              }
            />

            <DatePicker
              selectedDate={archiveToDate}
              dateChange={date =>
                dispatch(
                  changeArchiveFilter({
                    value: 'archiveToDate',
                    label: date.format('YYYY-MM-DD'),
                  })
                )
              }
            />
          </FrameFilter>
          <FrameFilter>
            <Typography variant='subtitle2'>Заполнение записи</Typography>
            <RadioButton
              value={complete}
              onChange={event =>
                dispatch(changeArchiveFilter({ value: 'complete', label: event.target.value }))
              }
              trueLabel={'Частичное'}
              falseLabel={'Полное'}
              trueValue={'non-complete'}
              falseValue={'complete'}
            />
          </FrameFilter>

          <FrameFilter>
            <Typography variant='subtitle2'>Архивная версия документа</Typography>
            <RadioButton
              value={archive}
              onChange={event =>
                dispatch(changeArchiveFilter({ value: 'archive', label: event.target.value }))
              }
              trueLabel={'Есть'}
              falseLabel={'Удалена'}
              trueValue={'not-deleted'}
              falseValue={'deleted'}
            />
          </FrameFilter>
          <Button
            onClick={handleResetFilter}
            label='Сбросить все значения'
            color='secondary'
            fullWidth
          />
        </Wrapper>
      </RightSideContainer>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
        <Container sx={{ paddingRight: count > 0 ? '50px' : '0px' }}>
          <div style={{ width: isMobile ? '80%' : '100%' }}>
            <SearchQuery
              searchQuery={searchQuery}
              setSearchQuery={changeSearchQuery}
              disabled={false}
            />
          </div>

          <IconFrame>
            {count > 0 ? (
              <Tooltip title='Для скачивания PDF отключите фильтры'>
                <div style={{ cursor: 'default', opacity: '40%' }}>
                  <Pdf />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Скачать в формате PDF'>
                <div style={{ cursor: 'pointer' }} onClick={handleDownloadPdf}>
                  <Pdf />
                </div>
              </Tooltip>
            )}
            <Tooltip title='Скачать документ в Csv'>
              <div style={{ cursor: 'pointer' }} onClick={handleDownloadCsv}>
                <Csv />
              </div>
            </Tooltip>
            <Tooltip title='Скачать документ в Excel'>
              <div style={{ cursor: 'pointer' }} onClick={handleDownloadExcel}>
                <Excel />
              </div>
            </Tooltip>
          </IconFrame>

          <Filter sx={{ width: isMobile ? '220px' : '100%' }}>
            <Button
              onClick={() => {
                dispatch(setOpenRightContainer(true))
              }}
              startIcon={<FilterIcon />}
              label='Фильтры'
              color='secondary'
            />

            <CountLabel sx={{ opacity: count > 0 ? 1 : 0 }}>{count}</CountLabel>
          </Filter>
          {count > 0 && (
            <Fab
              onClick={handleResetFilter}
              size='small'
              style={{
                position: 'absolute',
                boxShadow:
                  '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                backgroundColor: '#E74953',
                left: '100%',
                marginLeft: '-48px',
                transform: 'scale(0.7)',
              }}
            >
              <CloseIcon style={{ color: '#f8f9fc' }} />
            </Fab>
          )}
        </Container>

        <Collapse in={selectedRows.length > 0} timeout={500} unmountOnExit>
          <div>
            {selectedRows.length > 1 && hasDeletedDocuments ? (
              <div>
                <Button
                  onClick={() => {
                    dispatch(setOpenModal(true))
                    dispatch(setTitle('Частичное скачивание PDF'))
                    dispatch(setBA('Скачать'))
                    dispatch(
                      setChildren(
                        'Внимание! Документы которые были удалены из архива (см. колонку “Дата уничтожения”), больше недоступны для скачивания в формате PDF.  Вы можете продолжить скачивание без них.'
                      )
                    )
                    dispatch(setConfirmAction(handleDownloadSelectionPdf))
                  }}
                  label='Скачать PDF выбранных документов'
                  color='text'
                  startIcon={<Pdf color={theme.palette.text.dark} />}
                />
              </div>
            ) : (
              <div>
                <Button
                  onClick={handleDownloadSelectionPdf}
                  label='Скачать PDF выбранных документов'
                  color='text'
                  startIcon={<Pdf color={theme.palette.text.dark} />}
                />
              </div>
            )}
          </div>
        </Collapse>
      </div>

      <Table
        colData={colData}
        rowData={rowData}
        rowCallback={handleShowClick}
        rowSelectionCallback={array => {
          const selectedTypeIds = array
            .map(selectedId => {
              const item = rowData.find(row => row.id === selectedId)
              return item ? item.TypeId : null
            })
            .filter(Boolean)
          const uniqueTypeIds = [...new Set(selectedTypeIds)]

          setSelectedJournalId(uniqueTypeIds)
          dispatch(
            changeArchived({
              value: 'selectedRows',
              label: array,
            })
          )
        }}
        hasCheckboxSelection={true}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
    </div>
  )
}

export default TableArchive
