// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Collapse from '@mui/material/Collapse'

import R from '../../../services/app/request.service'

import Cases from '../../components/Methodologies/views/ProtocolCases/ProtocolCases'
import TypeMethod from '../../components/Methodologies/views/TypeMethod/TypeMethod'
import WaterMi1592Copy from '../../components/Methodologies/water/Mi-1592-2015/CopyProtocol'

import {
  changeCase,
  resetCase,
  resetMeasurementId,
  resetCompanyId,
  resetMethodId,
} from '../../../store/slices/app/views/protocols'

import css from '../../components/Methodologies/styles.css'
const { BoxContainer } = css

const Copy = () => {
  const { protocol_id } = useParams()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isToggle = useSelector(state => state.toggleReducer.isToggle)
  const caseId = useSelector(state => state.protocolReducer.case.caseId)
  const pointId = useSelector(state => state.protocolReducer.case.pointId)

  const [data, setData] = useState([])

  useEffect(() => {
    ;(async () => {
      const { data } = await R.getProtocol(protocol_id)
      setData(data.data)

      dispatch(
        changeCase({
          value: 'caseId',
          label: data.data?.originalCase?.id,
        })
      )

      dispatch(
        changeCase({
          value: 'method',
          label: data.data?.originalCase?.verification_methods,
        })
      )

      dispatch(
        changeCase({
          value: 'verificationType',
          label: data.data?.originalCase?.verification_types,
        })
      )

      dispatch(
        changeCase({
          value: 'pointId',
          label: data.data?.point?.id,
        })
      )

      const initialPoint = data?.data?.originalCase?.points.map(item => ({
        id: item.id,
        name: item.name,
        additional: item.additional,
      }))

      dispatch(
        changeCase({
          value: 'pointMethod',
          label: initialPoint,
        })
      )
    })()
  }, [protocol_id, dispatch])

  const navigateToProtocol = () => {
    navigate('/metriva/protocols')
  }

  useEffect(() => {
    return () => {
      dispatch(resetCase())
      dispatch(resetMeasurementId())
      dispatch(resetCompanyId())
      dispatch(resetMethodId())
    }
  }, [])

  return (
    <main className={isToggle ? 'main_open' : 'main'}>
      <div className='path_to_back' onClick={navigateToProtocol}>
        <i
          className='fas fa-arrow-left icon_back'
          style={{ width: '30px', height: '30px', marginRight: '6px' }}
        />
        <p>Назад к списку протоколов</p>
      </div>

      <div style={{ margin: '14px 0' }}>
        <Cases data={data} />
      </div>

      <Collapse in={!!caseId}>
        <BoxContainer margin={'0'}>
          <TypeMethod />
        </BoxContainer>
      </Collapse>

      {(pointId === 1 || pointId === 3) && <WaterMi1592Copy />}
    </main>
  )
}

export default Copy
