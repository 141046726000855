const DeleteWithTimerIcon = ({ color = '#899298', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 18 18'
      onClick={onClick}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.642857 3.53792C0.287817 3.53792 0 3.84883 0 4.23236C0 4.61589 0.287817 4.9268 0.642857 4.9268H14.3571C14.7122 4.9268 15 4.61589 15 4.23236C15 3.84883 14.7122 3.53792 14.3571 3.53792H12.2143C11.7732 3.52585 11.3068 3.22248 11.1567 2.77422C11.1527 2.76244 11.1482 2.7479 11.1319 2.69515L11.0363 2.38508C10.9777 2.19495 10.9267 2.02932 10.8554 1.88127C10.5734 1.29637 10.0517 0.890217 9.44892 0.786231C9.29633 0.75991 9.13474 0.760022 8.94926 0.760149H6.05088C5.8654 0.760022 5.70381 0.75991 5.55122 0.786231C4.9484 0.890217 4.42674 1.29637 4.14479 1.88127C4.07342 2.02932 4.02242 2.19495 3.96388 2.38508L3.86821 2.69515C3.85193 2.7479 3.8474 2.76244 3.84345 2.77422C3.69335 3.22248 3.30419 3.52547 2.86306 3.53755L0.642857 3.53792ZM8.03642 17.4267H7.38064H7.38062C5.12439 17.4267 3.99627 17.4267 3.26278 16.7073C2.52929 15.988 2.45425 14.808 2.30417 12.4481L2.08791 9.04767C2.00648 7.7672 1.96576 7.12697 2.33373 6.72126C2.7017 6.31555 3.32309 6.31555 4.56588 6.31555H10.8512C12.094 6.31555 12.7154 6.31555 13.0833 6.72126C13.1794 6.82716 13.2476 6.94905 13.2945 7.09413C13.2654 7.09367 13.2362 7.09344 13.207 7.09344C10.1695 7.09344 7.70703 9.55588 7.70703 12.5934C7.70703 14.6318 8.81583 16.4111 10.4632 17.3612C9.83802 17.4267 9.05136 17.4267 8.03643 17.4267H8.03642Z'
        fill={color}
      />
      <path
        d='M8.70703 12.5935C8.70703 11.4 9.18114 10.2554 10.0251 9.41153C10.869 8.56761 12.0136 8.09351 13.207 8.09351C14.4005 8.09351 15.5451 8.56761 16.389 9.41153C17.2329 10.2554 17.707 11.4 17.707 12.5935C17.707 13.787 17.2329 14.9316 16.389 15.7755C15.5451 16.6194 14.4005 17.0935 13.207 17.0935C12.0136 17.0935 10.869 16.6194 10.0251 15.7755C9.18114 14.9316 8.70703 13.787 8.70703 12.5935ZM13.207 10.0935C12.932 10.0935 12.707 10.3185 12.707 10.5935V12.5935C12.707 12.8685 12.932 13.0935 13.207 13.0935H14.707C14.982 13.0935 15.207 12.8685 15.207 12.5935C15.207 12.3185 14.982 12.0935 14.707 12.0935H13.707V10.5935C13.707 10.3185 13.482 10.0935 13.207 10.0935Z'
        fill={color}
      />
    </svg>
  )
}

export default DeleteWithTimerIcon
