const WarningIcon = ({ color = '#FF5963', width = 24, height = 24 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M19.5 11.75C19.5 16.0302 16.03 19.5 11.75 19.5C7.46996 19.5 4 16.0302 4 11.75C4 7.46983 7.46996 4 11.75 4C16.03 4 19.5 7.46983 19.5 11.75ZM13.0862 15.2909C13.0862 15.6472 12.9568 15.9502 12.6989 16.1995C12.44 16.4489 12.1237 16.5737 11.75 16.5737C11.5684 16.5737 11.4003 16.5443 11.2468 16.4856C11.0829 16.4233 10.9347 16.3279 10.8011 16.1995C10.6946 16.0961 10.61 15.9835 10.5474 15.8616C10.4587 15.6897 10.4138 15.4995 10.4138 15.2909C10.4138 14.9257 10.5432 14.6184 10.8011 14.3689C11.06 14.1195 11.3763 13.9948 11.75 13.9948C12.1237 13.9948 12.44 14.1195 12.6989 14.3689C12.8336 14.4989 12.9328 14.6446 12.9975 14.8061C13.057 14.9544 13.0862 15.1161 13.0862 15.2909ZM10.989 12.1776C11.0422 12.5606 11.0913 12.8991 11.1351 13.1931H12.3649L12.4452 12.6514L12.5121 12.1776C12.5653 11.7945 12.6185 11.3981 12.6718 10.9883C12.7344 10.5786 12.7876 10.191 12.8325 9.82581C12.867 9.58036 12.8973 9.35983 12.9202 9.16423L12.9526 8.87715C12.9881 8.61879 13.0058 8.45398 13.0058 8.38273C13.0058 8.04424 12.8816 7.76369 12.6321 7.54095C12.392 7.3182 12.0976 7.2069 11.75 7.2069C11.4024 7.2069 11.1049 7.3182 10.8554 7.54095C10.6142 7.76369 10.4942 8.04424 10.4942 8.38273L10.4963 8.45307C10.5015 8.54154 10.514 8.68286 10.5338 8.87715C10.5704 9.13552 10.61 9.4517 10.6549 9.82581C10.7082 10.191 10.7614 10.5786 10.8147 10.9883C10.8773 11.3981 10.9347 11.7945 10.989 12.1776Z'
        fill={color}
      />
    </svg>
  )
}

export default WarningIcon
