import React from 'react'
import Selector from '../Selector/reactSelect/Selector'

const Year = ({ releaseYear, setReleaseYear }) => {
  const yearlist = Array.from({ length: 25 }, (item, index) => (2000 + index).toString())

  yearlist.push('Не выбрано')

  const options = yearlist
    ? yearlist.map(item => ({
        value: item,
        label: item,
      }))
    : []

  const getValue = selectedOption => {
    setReleaseYear && setReleaseYear(selectedOption ? selectedOption.value : '')
  }

  return (
    <Selector
      placeholder={'Выберите год выпуска'}
      options={options}
      onChange={getValue}
      value={options.find(option => option.value === releaseYear)}
      isClearable={true}
      isSearchable={true}
      noOptionsMessage={() => 'Нет доступных годов выпуска'}
      label={{
        text: 'Год выпуска',
        topBg: 'white',
        bottomBg: 'white',
      }}
    />
  )
}

export default Year
